import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard/Dashboard";

import Landing from "./pages/Landing/Landing";
import Profile from "./pages/Profile/Profile";
import Gen2 from "./pages/Gen2/Gen2";
import Gen2Bid from "./pages/bid/Gen2Bid";
import Gen2Page from "./pages/Gen2Page/Gen2Page";
import Market from "./pages/Market/Market";
import Airdrop from "./pages/airdrop/Aidrop";
function App() {
  // const [loginResponse, setLoginResponse] = useState();
  // useEffect(async () => {}, []);
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Landing />} />
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/u/:username' element={<Profile />} />
        <Route path = "/gen2" element= {<Gen2Page/>} />
        <Route path='/GEN2/:nftHashHex' element={<Gen2 />} />
        <Route path = "/gen2/bid/" element = {<Gen2Bid/>} />
        <Route path = "/market" element = {<Market/>} />
        <Route path = "/airdrop" element = {<Airdrop/>} />
        <Route path='*' element={<h1>Not Found</h1>} />
      </Routes>
    </Router>
  );
}
export default App;
