import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar";
import { useParams } from "react-router-dom";
import Deso from "deso-protocol";
import toast, { Toaster } from "react-hot-toast";
import { values, originals } from "../../tools/constants";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
export default function Profile() {
  const params = useParams();
  const deso = new Deso();
  const username = params.username;
  const [userInfo, setUserInfo] = useState(null);
  const [originalApes, setOriginalApes] = useState(originals);
  const [specials, setSpecials] = useState(values);
  const [userNotFound, setUserNotFound] = useState(false);
  const [isBuying, setIsBuying] = useState(false);

  const [loadingUser, setLoadingUser] = useState(true);
  const [tab, setTab] = useState("originals");

  const [loadingApes, setLoadingApes] = useState(true);

  const [apeInfo, setApeInfo] = useState(null);
  const [sortedApes, setSortedApes] = useState(null);

  const [specialsOwned, setSpecialsOwned] = useState(null);
  const [originalOwned, setOriginalOwned] = useState(null);

  const [onSalePunks, setOnSalePunks] = useState(null);

  useEffect(() => {
    async function getUserInfo() {
      let usernameVal = username.length == 55 ? "" : username;
      let pkVal = username.length == 55 ? username : "";
      const request = {
        PublicKeyBase58Check: pkVal,
        Username: usernameVal,
        NoErrorOnMissing: false,
      };
      try {
        const response = await deso.user.getSingleProfile(request);
        const pk = response.Profile.PublicKeyBase58Check;
        const userInfoVal = {
          username: response.Profile.Username,
          description: response.Profile.Description,
          publicKey: pk,
          profilePic: true
            ? response.Profile.ExtraData
              ? response.Profile.ExtraData.LargeProfilePicURL !== ""
                ? response.Profile.ExtraData.LargeProfilePicURL
                : `https://diamondapp.com/api/v0/get-single-profile-picture/${pk}?fallback=https://diamondapp.com/assets/img/default-profile-pic.png`
              : `https://diamondapp.com/api/v0/get-single-profile-picture/${pk}?fallback=https://diamondapp.com/assets/img/default-profile-pic.png`
            : `https://diamondapp.com/api/v0/get-single-profile-picture/${pk}?fallback=https://diamondapp.com/assets/img/default-profile-pic.png`,
        };
        setUserInfo(userInfoVal);
        setLoadingUser(false);

        //mkae post request to get apes
        const res2 = await fetch("https://talkbuddy.in/get-ape-by-owner", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            owner: pk,
          }),
        });
        const data = await res2.json();
        if (data.status == 200) {
          const apes = data.apeInfo;
          setApeInfo(apes);
          let cheapApeList = [];
          let specialOnes = Object.keys(specials);
          let specialApeList = [];
          for (const key in apes) {
            let postHashHex = key.split("-")[0];
            let newDict = {
              buyNowPrice: apes[key].buyNowPrice,
              owner: apes[key].owner,
              minBidDeso: apes[key].minBidDeso,
              buyNowPrice: apes[key].buyNowPrice,
              postHashHex: postHashHex,
              serialNumber: key.split("-")[1],
            };
            //check if postHashHex is in specialOnes
            if (!specialOnes.includes(postHashHex)) {
              cheapApeList.push(newDict);
            } else {
              specialApeList.push(newDict);
            }
          }
          cheapApeList.sort((a, b) => a.buyNowPrice - b.buyNowPrice);
          setSortedApes(cheapApeList);

          setSpecialsOwned(specialApeList);

          let originalOwned = [];
          for (const postHashHex in originalApes) {
            let serialList = [];
            for (const key in cheapApeList) {
              let currentPostHashHex = cheapApeList[key].postHashHex;
              let currentSerial = cheapApeList[key].serialNumber;

              if (currentPostHashHex == postHashHex) {
                serialList.push(currentSerial);
              }
            }
            originalOwned.push({
              postHashHex: postHashHex,
              serialNumbers: serialList,
              serialNumbersLength: serialList.length,
              Punkname: originalApes[postHashHex].name.slice(0, 18),
              punkImageName: originalApes[postHashHex].name.slice(15, 18),
            });
          }
          //sort originalOwned based on length of serialNumbers
          originalOwned.sort(
            (a, b) => b.serialNumbers.length - a.serialNumbers.length
          );

          setOriginalOwned(originalOwned);

          //loop through cheapApeList and check if any of the apes are on sale and buyNowPrice is more than 0
          let onSalePunks = [];
          for (const key in cheapApeList) {
            if (cheapApeList[key].buyNowPrice > 0) {
              onSalePunks.push({
                postHashHex: cheapApeList[key].postHashHex,
                serialNumber: cheapApeList[key].serialNumber,
                buyNowPrice: cheapApeList[key].buyNowPrice,
                Punkname: originalApes[
                  cheapApeList[key].postHashHex
                ].name.slice(0, 18),
                punkImageName: originalApes[
                  cheapApeList[key].postHashHex
                ].name.slice(15, 18),
              });
            }
          }
          //sort onSalePunks based on buyNowPrice
          onSalePunks.sort((a, b) => a.buyNowPrice - b.buyNowPrice);
          setOnSalePunks(onSalePunks);

          setLoadingApes(false);
        }

        if (data.status == 404) {
          setLoadingApes(false);
        }
      } catch (err) {
        console.log(err);
        setUserNotFound(true);
        setLoadingUser(false);
      }
    }
    if (username) {
      if (userInfo) {
        if (userInfo.username) {
          if (userInfo.username != username) {
            getUserInfo();
          }
        }
      }
      if (!userInfo) {
        getUserInfo();
      }
    }
  }, [username]);

  const buyNFT = async (postHashHex, serial, price) => {
    const loggedInPublicKey = localStorage.getItem("loggedInUser");

    if (!loggedInPublicKey) {
      toast.error("Please login to buy");
      return;
    }
    if (isBuying) {
      toast.error("Please wait for the previous transaction to complete");
      return;
    }
    const loadingToast = toast.loading("Please wait...");
    setIsBuying(true);
    const request = {
      UpdaterPublicKeyBase58Check: loggedInPublicKey,
      NFTPostHashHex: postHashHex,
      SerialNumber: parseInt(serial),
      BidAmountNanos: parseInt(price),
      MinFeeRateNanosPerKB: 1000,
    };
    try {
      const response = await deso.nft.createNftBid(request);

      setIsBuying(false);
      toast.dismiss(loadingToast);
      if (response) {
        const error = response.error;

        if (error) {
          toast.error(error);
          return;
        }
        toast.success("Transaction successful!");

        let newList = [];
        for (const key in onSalePunks) {
          if (
            onSalePunks[key].postHashHex +
              "-" +
              onSalePunks[key].serialNumber !==
            postHashHex + "-" + serial
          ) {
            newList.push(onSalePunks[key]);
          }
        }
        setOnSalePunks(newList);

        //make new post request and forget about response
        const res2 = await fetch("https://talkbuddy.in/update-nft-info", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            postHashHex: postHashHex,
          }),
        });
      } else {
        setIsBuying(false);
        toast.error("Something went wrong. This NFT might not be on sale");
        toast.dismiss(loadingToast);
      }
    } catch (e) {
      setIsBuying(false);
      toast.error("Something went wrong. This NFT might not be on sale");
      toast.dismiss(loadingToast);
    }
  };

  return (
    <div className='w-full bg-[#150026] overflow-x-hidden'>
      <Navbar showLogin={true} showLogo={true} />
      <div className='bg-[#150026]'>
        {loadingUser && (
          <div className='flex justify-center items-center h-screen mt-2 bg'>
            <div className='animate-spin rounded-full h-32 w-32 border-b-2 border-red-500'></div>
          </div>
        )}
        <Toaster />
        {userInfo && (
          <div className=' w-full'>
            <div className='flex flex-col items-center justify-center mt-10'>
              <div>
                <div className='flex items-center space-x-2'>
                  <img
                    src={userInfo.profilePic}
                    className='rounded-full h-24 w-24'
                  />
                  <div>
                    <p className='text-2xl font-bold text-white'>
                      {userInfo.username}
                    </p>
                    <div>
                      <button
                        className='bg-[#350353] text-sm mt-3 hover:bg-[#381949] text-white py-2 px-4 rounded'
                        onClick={() => {
                          navigator.clipboard.writeText(userInfo.publicKey);
                          toast.success("Copied to clipboard!");
                        }}>
                        {userInfo.publicKey.slice(0, 20)}...
                      </button>
                    </div>
                  </div>
                </div>
                <p className='text-sm  text-ellipsis text-white  mt-4 w-full sm:w-96'>
                  {userInfo.description}
                </p>
              </div>
            </div>
            <div className='w-full mt-12 px-2 justify-center mx-auto flex space-x-4 text-white font-semibold text-lg '>
              <button
                className={`${
                  tab === "originals"
                    ? "border-b-4 border-b-[#4f237c]"
                    : "text-gray-400"
                } hover:text-white pb-2`}
                onClick={() => setTab("originals")}>
                Originals
              </button>
              <button
                className={`${
                  tab === "specials"
                    ? "border-b-4 border-b-[#4f237c]"
                    : "text-gray-400"
                } hover:text-white pb-2`}
                onClick={() => setTab("specials")}>
                Specials
              </button>
              <button
                className={`${
                  tab === "onSale"
                    ? "border-b-4 border-b-[#4f237c]"
                    : "text-gray-400"
                } hover:text-white pb-2`}
                onClick={() => setTab("onSale")}>
                On Sale
              </button>
            </div>
            <div className=' min-h-screen'>
              {loadingApes && (
                <div className='flex justify-center items-center h-screen mt-4 bg'>
                  <div className='animate-spin rounded-full h-32 w-32 border-b-2 border-red-500'></div>
                </div>
              )}
              {!loadingApes && apeInfo && tab == "originals" && (
                <div className='w-full flex flex-col items-center justify-center text-white mt-3 text-lg'>
                  {userInfo.username} owns {Object.keys(apeInfo).length}{" "}
                  CyberPunkApes
                </div>
              )}
              {!loadingApes && !apeInfo && (
                <div className='w-full flex flex-col items-center justify-center text-white mt-3 text-lg'>
                  {userInfo.username} owns 0 CyberPunkApes
                </div>
              )}

              {tab === "originals" && !loadingApes && originalOwned && (
                <div className='w-full mx-auto px-3 masonaryDiv'>
                  <ResponsiveMasonry
                    columnsCountBreakPoints={{ 350: 1, 750: 3, 900: 4 }}>
                    <Masonry gutter='10px'>
                      {originalOwned.map((item, index) => (
                        <div
                          className={` flex flex-col  space-y-2 my-4 ${
                            item.serialNumbersLength == 0
                              ? "bg-[#150522] "
                              : "bg-[#20072e] hover:bg-[#28093a] "
                          }rounded-lg shadow-lg  p-2 `}
                          key={index}>
                          <div className='flex space-x-2 flex-col items-center my-3'>
                            <img
                              src={`/CPA_ORIGINALS/${item.punkImageName}.jpg`}
                              className={`w-56 h-56 object-contain rounded-md  ${
                                item.serialNumbersLength == 0
                                  ? "opacity-20"
                                  : ""
                              }`}
                            />
                            <a
                              className={`font-semibold text-lg ${
                                item.serialNumbersLength == 0
                                  ? "text-gray-600"
                                  : "text-blue-300"
                              }`}
                              href={`https://diamondapp.com/nft/${item.postHashHex}`}
                              target='_blank'>
                              {item.Punkname}
                            </a>
                          </div>
                          <div
                            className={`flex space-x-2 items-center text-white ${
                              item.serialNumbersLength == 0
                                ? "text-gray-600"
                                : "text-white"
                            }`}>
                            <p className={` font-semibold text-lg`}>Owns:</p>
                            <p className=''>
                              {" "}
                              {`${item.serialNumbersLength} serial${
                                item.serialNumbersLength == 1 ||
                                item.serialNumbersLength
                                  ? ""
                                  : "s"
                              }`}{" "}
                            </p>
                          </div>
                          <div
                            className={`flex space-x-2 ${
                              item.serialNumbersLength == 0
                                ? "text-gray-600"
                                : "text-white"
                            }`}>
                            <p className=' font-semibold text-lg -mt-1'>
                              Serials:
                            </p>
                            <p className=' flex flex-wrap '>
                              {item.serialNumbers.map((serial, index) => (
                                <p className='text-gray-200' key={index}>
                                  {serial}{" "}
                                  {index + 1 != item.serialNumbersLength && ","}
                                </p>
                              ))}
                            </p>
                          </div>
                        </div>
                      ))}
                    </Masonry>
                  </ResponsiveMasonry>
                </div>
              )}

              {!loadingApes && onSalePunks && tab == "onSale" && (
                <div className='w-full flex flex-col items-center justify-center text-white mt-3 text-lg'>
                  {userInfo.username} has put {onSalePunks.length} CyberPunkApes
                  on sale
                </div>
              )}

              {tab === "onSale" && !loadingApes && (
                <div className='my-8'>
                  {onSalePunks.map((item, index) => (
                    <div className='flex flex-col items-center '>
                      <div
                        className='grid grid-cols-3 text-white my-4 text-sm sm:text-base '
                        key={index}>
                        <a
                          className='flex space-x-1 items-center  col-span-2'
                          href={`https://diamondapp.com/nft/${item.postHashHex}`}
                          target='_blank'>
                          <img
                            src={`/CPA_ORIGINALS/${item.punkImageName}.jpg`}
                            className='w-9 h-9 sm:w-14 sm:h-14 object-contain rounded-md'></img>

                          <div className=''>
                            # <span> {item.Punkname} </span>
                            <span>
                              <p>{`Serial: ${item.serialNumber}`}</p>
                            </span>
                          </div>
                        </a>

                        <div>
                          <button
                            className='bg-green-500 hover:bg-green-700 text-white sm:font-bold py-2 px-6 rounded-md'
                            onClick={() => {
                              buyNFT(
                                item.postHashHex,
                                item.serialNumber,
                                item.buyNowPrice
                              );
                            }}>
                            {Math.round(item.buyNowPrice / 1e6) / 1000} $DESO
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {tab == "specials" && !loadingApes && (
                <div className='w-full sm:w-3/4 md:w-1/3 mx-auto'>
                  <ResponsiveMasonry
                    columnsCountBreakPoints={{ 350: 1, 750: 3, 900: 1 }}>
                    <Masonry gutter='10px'>
                      {specialsOwned.map((item, index) => (
                        <div className='flex flex-col justify-center items-center relative px-2 sm:px-4 my-1 sm:my-2 lg:my-3 '>
                          <a
                            href={`https://diamondapp.com/nft/${item.postHashHex}`}
                            target='_blank'>
                            <img
                              className='w-full rounded-t-md hover:shadow-purple-800 hover:shadow-lg'
                              src={specials[item.postHashHex].img}
                            />
                          </a>
                          <div className='bg-[#350353] w-full rounded-b-md px-2 py-3'>
                            <h1 className='text-white font-semibold'>
                              {specials[item.postHashHex].name}
                            </h1>
                            {item.isForSale === "0" ? (
                              <div className='bg-gray-900 text-gray-200 px-4 py-1 rounded-md my-2'>
                                Not For Sale
                              </div>
                            ) : (
                              <button className='bg-green-600 text-gray-100 px-4 py-1 rounded-md hover:bg-green-700 text-sm lg:text-base my-2'>
                                Bid {Math.round(item.buyNowPrice / 1e9)} $DESO
                              </button>
                            )}
                          </div>
                        </div>
                      ))}
                    </Masonry>
                  </ResponsiveMasonry>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
