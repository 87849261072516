const DEFAULT_NODE_URL = "https://node.deso.org/api";
const DEFAULT_BACKEND_URL = "https://groupfollow.xyz";
// const DEFAULT_BACKEND_URL = "http://127.0.0.1:5000/";
const SELF_PUBLIC_KEY = "BC1YLh7ak5xAryx9M9CmtCWcEWqknyzXcCULnqW49HRztUjkzQv6L5Z";
//const DEFAULT_NODE_URL = "https://api.desodev.com/api"
let client = null;

class DesoApi {
  constructor() {
    this.baseUrl = DEFAULT_NODE_URL;
  }

  async initDerivedKeys(user) {
    const path = DEFAULT_BACKEND_URL + "/init-derived-keys";
    const data = {
      jwt: user.jwt,
      publicKeyBase58Check: user.publicKeyBase58Check,
      accessSignature: user.accessSignature,
      derivedPublicKeyBase58Check: user.derivedPublicKeyBase58Check,
      derivedSeedHex: user.derivedSeedHex,
      expirationBlock: user.expirationBlock,
      transactionSpendingLimitHex: user.transactionSpendingLimitHex,
    };
    return this.postData(path, data);
  }

  async verifyMetamaskWithDeso(desoPublicKey, jwt, messageObj) {
    const path = DEFAULT_BACKEND_URL + "/verify-metamask-with-deso";
    const data = {
      desoPublicKey: desoPublicKey,
      jwt: jwt,
      messageObj: messageObj,
    };
    return this.postData(path, data);
  }

  async getUserInfo(desoPublicKey) {
    const path = DEFAULT_BACKEND_URL + "/get-user-info";
    const data = {
      desoPublicKey: desoPublicKey,
    };
    return this.postData(path, data);
  }

  async getCommunity(contractAddress) {
    const path = DEFAULT_BACKEND_URL + "/get-community";
    const data = {
      contractAddress: contractAddress,
    };
    return this.postData(path, data);
  }

  async logoutUser(user) {
    const path = DEFAULT_BACKEND_URL + "/logout";
    const data = {
      jwt: user.jwt,
      publicKeyBase58Check: user.publicKeyBase58Check,
    };
    return this.postData(path, data);
  }

  async getRecommended(limit) {
    const path = DEFAULT_BACKEND_URL + "/get-recommended";
    const data = {
      limit: limit,
    };
    return this.postData(path, data);
  }

  async createFollowTxn(publicKey, publicKeyToFollow, isFollow) {
    if (!publicKey) {
      console.log("publicKey is required");
      return;
    }

    if (!publicKeyToFollow) {
      console.log("publicKeyToFollow is required");
      return;
    }

    const path = "/v0/create-follow-txn-stateless";
    const data = {
      FollowerPublicKeyBase58Check: publicKey,
      FollowedPublicKeyBase58Check: publicKeyToFollow,
      IsUnfollow: !isFollow,
      MinFeeRateNanosPerKB: 2000,
    };
    return this.postData(path, data);
  }

  async listCommunity(contractAddress, NftName, projectDescription) {
    const path = DEFAULT_BACKEND_URL + "/list-community";
    const data = {
      contractAddress: contractAddress,
      NftName: NftName,
      projectDescription: projectDescription,
    };
    return this.postData(path, data);
  }

  async getContractInfo(contractAddress) {
    const path = DEFAULT_BACKEND_URL + "/get-contract-info";
    const data = {
      contractAddress: contractAddress,
    };
    return this.postData(path, data);
  }

  async subscribeCommunity(contractAddress, desoPublicKey, jwt) {
    const path = DEFAULT_BACKEND_URL + "/subscribe-community";
    const data = {
      contractAddress: contractAddress,
      desoPublicKey: desoPublicKey,
      jwt: jwt,
    };
    return this.postData(path, data);
  }

  async discoverCommunities(limit) {
    const path = DEFAULT_BACKEND_URL + "/discover-communities";
    const data = {
      limit: limit,
    };
    return this.postData(path, data);
  }

  async postData(url, data) {
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      console.log(error);
      return null;
    }
  }
}

export default DesoApi;
