const values = {
  a64e9ded29fbddb3ff2b90138ac065138d76bbd2372cb431ab8621677b7017d6: {
    name: "CyberPunkApes #10",
    img: "https://desonftfiles.azureedge.net/public/BC1YLhM4vH9FTepmEm9jCBrKmR46vW5n517YEopsuLT49JQHw674KE2/a64e9ded29fbddb3ff2b90138ac065138d76bbd2372cb431ab8621677b7017d6/orig.jpg",
    is10: true,
  },
  "696491fcfb7f26e675f1bc1b83d6525749551d0f97b7de5ac0bc0731fc77156a": {
    name: "CyberPunkApes #20",
    img: "https://desonftfiles.azureedge.net/public/BC1YLhM4vH9FTepmEm9jCBrKmR46vW5n517YEopsuLT49JQHw674KE2/696491fcfb7f26e675f1bc1b83d6525749551d0f97b7de5ac0bc0731fc77156a/orig.jpg",
    is10: true,
  },
  b262e5c3d9f766418f00dacfd58483ad48cb3e32a2f3341df5890570fba1cbac: {
    name: "CyberPunkApes #30",
    img: "https://desonftfiles.azureedge.net/public/BC1YLhM4vH9FTepmEm9jCBrKmR46vW5n517YEopsuLT49JQHw674KE2/b262e5c3d9f766418f00dacfd58483ad48cb3e32a2f3341df5890570fba1cbac/orig.jpg",
    is10: true,
  },
  f3e9bbc6433028a142a2d90aed6daef1f5b16d30f7f13cb2451cdc1e46eff9a1: {
    name: "CyberPunkApes #40",
    img: "https://desonftfiles.azureedge.net/public/BC1YLhM4vH9FTepmEm9jCBrKmR46vW5n517YEopsuLT49JQHw674KE2/f3e9bbc6433028a142a2d90aed6daef1f5b16d30f7f13cb2451cdc1e46eff9a1/orig.jpg",
    is10: true,
  },
  "506179dd23dfd6943bd80eaafe3000109167403169f923d80105bbcc8a7ad659": {
    name: "CyberPunkApes #50",
    img: "https://desonftfiles.azureedge.net/public/BC1YLhM4vH9FTepmEm9jCBrKmR46vW5n517YEopsuLT49JQHw674KE2/506179dd23dfd6943bd80eaafe3000109167403169f923d80105bbcc8a7ad659/orig.jpg",
    is10: true,
  },
  "7c5be183238223ba7452327230cc84f53db8c2ab93cbd47ef08df49f995c10ca": {
    name: "CyberPunkApes #60",
    img: "https://desonftfiles.azureedge.net/public/BC1YLhM4vH9FTepmEm9jCBrKmR46vW5n517YEopsuLT49JQHw674KE2/7c5be183238223ba7452327230cc84f53db8c2ab93cbd47ef08df49f995c10ca/orig.jpg",
    is10: true,
  },
  "388fba30db3875417d41428d65a186dd74eff5bc0fce17623b2ae293258abc65": {
    name: "CyberPunkApes #070",
    img: "https://desonftfiles.azureedge.net/public/BC1YLhM4vH9FTepmEm9jCBrKmR46vW5n517YEopsuLT49JQHw674KE2/388fba30db3875417d41428d65a186dd74eff5bc0fce17623b2ae293258abc65/orig.jpg",
    is10: true,
  },
  "06ae76478a754992167ee0c664daef5d90d9579165f5d78f1e788096e39ee6db": {
    name: "CyberPunkApes #080",
    img: "https://desonftfiles.azureedge.net/public/BC1YLhM4vH9FTepmEm9jCBrKmR46vW5n517YEopsuLT49JQHw674KE2/06ae76478a754992167ee0c664daef5d90d9579165f5d78f1e788096e39ee6db/orig.jpg",
    is10: true,
  },
  ee49be27dd4e8c819b14aa1a9888246964cbb2787bbf2ba2524472b47053d821: {
    name: "CyberPunkApes #090",
    img: "https://desonftfiles.azureedge.net/public/BC1YLhM4vH9FTepmEm9jCBrKmR46vW5n517YEopsuLT49JQHw674KE2/ee49be27dd4e8c819b14aa1a9888246964cbb2787bbf2ba2524472b47053d821/orig.jpg",
    is10: true,
  },

  a5b261dd448cde884850cd80ab2562973867ece33d316f6655b08c72c1407d0d: {
    name: "CyberPunkApes #100",
    img: "https://uirfs5kngr6eh7v62lfsd4wvr3hljsix7wglc4riozi7wbxb544q.arweave.net/oiJZdU00fEP-vtLLIfLVjs60yRf9jLFyKHZR-wbh7zk",
    is10: true,
  },
};

const originals = {
  "996809675efd11ebcfec280a3998cb8a1d2c9c8afea8bd435578869b0423b71c": {
    name: "CyberPunkApes #001\n\nComment on this post to claim your NFT for free.",
    img: "https://bjedmgpaczcmhcz62upa6wscmvjwrg3lvsvy4umtwzbohkb7kwya.arweave.net/Ckg2GeAWRMOLPtUeD1pCZVNom2usq45Rk7ZC46g_VbA",
  },
  "168faa60f94e10667f78c05ed00924f121a3b9d8dba25e2369de15aa04cbc933": {
    name: "CyberPunkApes #002\n\nComment on this post to claim your NFT for free.",
    img: "https://azhby7kspxclgvdj5v5dbafgtz4zowrsbpvavrzn2ybnkjf4xfna.arweave.net/Bk4cfVJ9xLNUae16MICmnnmXWjIL6grHLdYC1SS8uVo",
  },
  f0ed6b0865e8b440635fecf63c7184eaea0202fad989d72a161305b395508031: {
    name: "CyberPunkApes #003\n\nComment on this post to claim your NFT for free.",
    img: "https://z7orhdhhgsmpxsy4ttziajqutmg5d3okgwgviuqy2lret5sjqeaa.arweave.net/z90TjOc0mPvLHJzygCYUmw3R7co1jVRSGNLiSfZJgQA",
  },
  "7f728955df598480df14f8d1c2aecb2f68be59b6dd90e8bdfd73f45fd87c3594": {
    name: "CyberPunkApes #004\n\nComment on this post to claim your NFT for free.",
    img: "https://pzng3fe2u7o2qglwjhngeqtseifrzyoazuepwn5lsmeahycubdea.arweave.net/flptlJqn3agZdknaYkJyIgsc4cDNCPs3q5MIA-BUCMg",
  },
  "769e8f85ae5135f16b78c3a05695e728f485bd777e9d215d3a81bf039a1c09f3": {
    name: "CyberPunkApes #005\n\nComment on this post to claim your NFT for free.",
    img: "https://7r5sqtcymjbofpk7xj6nhkzeywhwlpja7m2g3mvbjo3igjqwctgq.arweave.net/_HsoTFhiQuK9X7p806skxY9lvSD7NG2yoUu2gyYWFM0",
  },
  f7504a9fafac73db78e5d7ab408f2e251db0121efc70765038cff57960558cf4: {
    name: "CyberPunkApes #006\n\nComment on this post to claim your NFT for free.",
    img: "https://yu4mdirplakf5qzrugvcwztxf6ycefacqizri2u3sdvu7nhrl4ba.arweave.net/xTjBoi9YFF7DMaGqK2Z3L7AiFAKCMxRqm5DrT7TxXwI",
  },
  "03d162e46995168266bb2c7243baa15839a6e0b5ce2d6e726072e78a1c9235cc": {
    name: "CyberPunkApes #007\n\nComment on this post to claim your NFT for free.",
    img: "https://bktzzbdybpqbd2dpsmrg5aciiw4j5jo4jnm4jzujaxja26jggxna.arweave.net/CqechHgL4BHob5MiboBIRbiepdxLWcTmiQXSDXkmNdo",
  },
  "41a69d7cbd82f29aacae1a246be7dd2935e41b5b7f941486dbfd4ae57130b86b": {
    name: "CyberPunkApes #008\n \nComment on this post to claim your NFT for free.",
    img: "https://yF-B8YyrHF9-yAc34mzXctjtgd3h1BDxTT_IMqqJCuS9MTjMun7MhtX9JHtRny_.arweave.net/5b9cJm5IHWr7UNW4wvRrhgEdW0QGVFkpxQkjNJT0x60",
  },
  d0188657e99b5aa5247a14ab844439a5261e0afbe450c8f907429af687002626: {
    name: "CyberPunkApes #009\n\nComment on this post to claim your NFT for free.",
    img: "https://ee2uvimcedlqwpsyq6wsg6oz3ssw27dofiaxmz2v5lmabe2nr6fa.arweave.net/ITVKoYIg1ws-WIetI3nZ3KVtfG4qAXZnVerYAJNNj4o",
  },
  "41a96938e156c9fee657b9cf695785e3589b4120f3b809eaae8c4fd94a0572f0": {
    name: "CyberPunkApes #011\n \nComment on this post to claim your NFT for free.",
    img: "https://28307XrqS4HI4ARLTs1Jz8XTLIIBO6rlaaPk-10kh7Yl77kWeftFvswDYTOIvmB.arweave.net/-Fba_fznu8mtCUFH65MyWQAOP_6y-hpSZ-h304wcVTY",
  },
  "486db5c7e54dd872b04196bb63df1292cfcf567c0d3877b336b5116e69e21ed7": {
    name: "CyberPunkApes #012\n\nComment on the post to claim your NFT for free",
    img: "https://dwyyekymgssueeylkmik2yo7gwnmeppbiochbbagloha5kwmsw6q.arweave.net/HbGCKww0pUITC1MQrWHfNZrCPeFDhHCEBluODqrMlb0",
  },
  "6082f2e570dc20adfa1d78bafc77181fd0652ebf2796ae3837449c6be312135e": {
    name: "CyberPunkApes #013\n \nComment on this post to claim your NFT for free.",
    img: "https://14dzQ9CxS_CrhCYxXMR50_rEHMCW_LvKzPDz7nqnXA_LY8egc1Sir-NzF7CaVAR.arweave.net/rO4Pg5GW8W-C3eooSOfaLrdqgAQ1J7U58zIKBCy7cWI",
  },
  "27dd192969d7c38c4fecc6119e3865c707ad66c5bc7766acb4258175b0fb4e84": {
    name: "CyberPunkApes #014\n \nComment on this post to claim your NFT for free.",
    img: "https://iemPJ2RFDUy1cwU4iwSIp2AyTTY_KS5nUqJDbo-sW_D3CfdtSPfGBeNRfzaoeiw.arweave.net/5VDP9yc__661q9WoIE2xDe6kw_QT7k92CV5oaYMohec",
  },
  f8d0bd981e8d170747c6939c3a1752791c3ecc225a5d121005764b0c0d349794: {
    name: "CyberPunkApes #015\n\nComment on this post to claim your NFT for free.",
    img: "https://EB7eZRfv49DDmHTpI8RqI2xUGgN2Jvw2RasOItCCHbfsZNBLbJOHCr3uAKmB8xu.arweave.net/yfdFyNsU_5Qs7Zl33YAZBvTLua0MzjR3UUggtx_IRd4",
  },
  "2c7234730fba765579218c52b75c1f9a7aa1d75fb264a7d38667af69840e0a68": {
    name: "CyberPunkApes #016\n\nComment on the post to claim your NFT for free",
    img: "https://ymxh7wior33uvbujccffvdu3v5k2cyidejpmf4ntllqppnaipjya.arweave.net/wy5_2Q6O90qGiRCKWo6br1WhYQMiXsLxs1rg97QIenA",
  },
  "821f5c40b4ae2262b31b46cc5470f83138c87fc46e9c38ae5b5e5e6d8ff2a686": {
    name: "CyberPunkApes #017\n\nComment on the post to claim your NFT for free",
    img: "https://4m6doleylyj6i5vuqlgnkphr4ffkakyzqlvmmj4pvcoxfqddjkvq.arweave.net/4zw3LJheE-R2tILM1Tzx4UqgKxmC6sYnj6idcsBjSqs",
  },
  a919c799f1d9d0ac5521bd810efc89e7f145578b59dc8b4bd9b8ff9ca35342ec: {
    name: "CyberPunkApes #018\n\nComment on the post to claim your NFT for free",
    img: "https://qughdw6urwytjd6rk56i3pslr6eu4fktnqkgnfhkb7zjegdmq6fa.arweave.net/hQxx29SNsTSP0Vd8jb5Lj4lOFVNsFGaU6g_ykhhsh4o",
  },
  "3df9dde0529760a80065c95be78d5e2303fc40ebbc2b86904f2cd37e4f25e06f": {
    name: "CyberPunkApes #019\n\nComment on the post to claim your NFT for free",
    img: "https://cpvp6zo7brcgwyxpyklqcdnmvmrssamb3zkna7op7wn4do7stn2a.arweave.net/E-r_Zd8MRGti78KXAQ2sqyMpAYHeVNB9z_2bwbvym3Q",
  },
  "62141b5ffaf2b2178f1ae0e482b526d4912fd54b960d1d122465ea2b84faf32a": {
    name: "CyberPunkApes #021\n\nComment on this NFT to claim it.",
    img: "https://EB7eZRfv49DDmHTpI8RqI2xUGgN2Jvw2RasOItCCHbfsZNBLbJOHCr3uAKmB8xu.arweave.net/kUfrc85GHlB5uECy1ZdsSQ559QpCdoMdXAmUpLl-F78",
  },
  ec3124e349b8ab71ca5abf610b25c58236712fb099c2cd4226c070a4d0dc9472: {
    name: 'CyberPunkApes #022\n\nComment anything except "claim" word to get this NFT',
    img: "https://72jreoevfwc2pj42ki467ksovch3ixki652y5zac3ovqujtntika.arweave.net/_pMSOJUthaenmlI576pOqI-0XUj3dY7kAturCiZtmhQ",
  },
  edfa660e0378fdfef0286a8510654cc6e1b39240849f1c8d7aa69677128032fb: {
    name: 'CyberPunkApes #023\n\nComment anything except "claim" word to get this NFT',
    img: "https://EB7eZRfv49DDmHTpI8RqI2xUGgN2Jvw2RasOItCCHbfsZNBLbJOHCr3uAKmB8xu.arweave.net/6iGs33IVSZ44NVPCHZMJxTrh3-H6mQr7X69ipB0upgs",
  },
  "90d80551cbf64c240c817c53636f27211655eaf1c157520deaf3daf21f370824": {
    name: 'CyberPunkApes #024\n\nComment anything except "claim" word to get this NFT',
    img: "https://xsuyl6asqg43yge2zyp4t6xa4w65ovbuwpdbj2ejk7twwdlso33a.arweave.net/vKmF-BKBubwYms4fyfrg5b3XVDSzxhToiVfnaw1ydvY",
  },
  cae8a5f93354bc439885b0de54e78471e77bf1510a0db972a77aa61fdb4fec17: {
    name: 'CyberPunkApes #025\n\nComment anything except "claim" word to get this NFT',
    img: "https://2vn4l4gf3ubnb3mnnvrbhopibkavfbalbc6smc4pl4v2z6tbxlea.arweave.net/1VvF8MXdAtDtjW1iE7noCoFShAsIvSYLj18rrPphusg",
  },
  "7d8ef54e50d527c53842699b8799905c6d40999d795baf8c6721ab61f620f796": {
    name: 'CyberPunkApes #026\n\nComment anything except "claim" word to get this NFT',
    img: "https://EB7eZRfv49DDmHTpI8RqI2xUGgN2Jvw2RasOItCCHbfsZNBLbJOHCr3uAKmB8xu.arweave.net/X34IANj9C1bfBtIQzdrGTKp0yA481eEh9uEx_n2-cFI",
  },
  "390ee69941df124ebe0b2ac9f9a175577aadb58117a1c757efe792e092374286": {
    name: 'CyberPunkApes #027\n\nComment anything except "claim" word to get this NFT',
    img: "https://i6bnzlz4b7xnuwsz35qwve2odsraaf72j6744l2hddu4isvzqcsa.arweave.net/R4LcrzwP7tpaWd9hapNOHKIAF_pPv84vRxjpxEq5gKQ",
  },
  "5cb9d64f70fb634a612c6a575ba0a42f20e5a6f0b079a73a54759a5f2ff6fa02": {
    name: "CyberPunkApes #028\n\nComment a word that starts with 'n' to get this NFT",
    img: "https://kxqjfrapkk7atly5gb75u3jczgq6e3o2lwymv4aoifvduvsbuqyq.arweave.net/VeCSxA9SvgmvHTB_2m0iyaHibdpdsMrwDkFqOlZBpDE",
  },
  c41e31a31ed9ce71308e6c28fffe8488a49d256f4d5eac0d8e40ee280d4b5d0d: {
    name: "CyberPunkApes #029\n\nComment a word that starts with 'd' to get this NFT",
    img: "https://EB7eZRfv49DDmHTpI8RqI2xUGgN2Jvw2RasOItCCHbfsZNBLbJOHCr3uAKmB8xu.arweave.net/vqBRywvGJIgBUhNnIwWJDN481RPY2dQ9M6a3xkn3MZM",
  },
  ed6f27b985762eb70eae345f78eb9b6d78815f66de6ec1ab071b28b32bcccac3: {
    name: "CyberPunkApes #031\n\nComment any word that starts with 'k' to get this NFT",
    img: "https://d7qx5nyd6fpvk5fy6gafefi4poe5fvd63r3ygscryzxf4tho62cq.arweave.net/H-F-twPxX1V0uPGAUhUce4nS1H7cd4NIUcZuXkzu9oU",
  },
  ca74612c1e80d28a974e5c0508675ce435287592f8f55ca41a8b198446fc471d: {
    name: "CyberPunkApes #032\n\nComment any word that starts with 'v' to get this NFT",
    img: "https://n3tjfq4q4sbz7fsrxn4vn6x2lozeivfqogqgv7r5dcuklktirglq.arweave.net/buaSw5Dkg5-WUbt5Vvr6W7JEVLBxoGr-PRiopapoiZc",
  },
  a940720034892e99415e72947daed2eaa9dda5cac4442d01e8c400f0734e5511: {
    name: "CyberPunkApes #033\n\nComment a word that starts with 'h' to get this NFT",
    img: "https://luk5rlrissaxvevrfmv44wemrqor7jyhzzxeqzk76y5gaf3lo5eq.arweave.net/XRXYriiUgXqSsSsrzliMjB0fpwfObkhlX_Y6YBdrd0k",
  },
  da2367ff113f93dbf98ae11c0659cf66a606f6a715ba76c501eead23bb77f794: {
    name: "CyberPunkApes #034\n\nComment a word that starts with 'i' to get this NFT",
    img: "https://EB7eZRfv49DDmHTpI8RqI2xUGgN2Jvw2RasOItCCHbfsZNBLbJOHCr3uAKmB8xu.arweave.net/7Nh732uLZX97FCOtVzD5jIQck5c-kSAduh7SBgUeclg",
  },
  "7cdce35d6c249761278cc2819f34c309b5d2ae9b4368b4b99596f84213a9981b": {
    name: "CyberPunkApes #035\n\nComment anything that starts with 'p' to get this NFT",
    img: "https://gg2fxe7obcoc5yu37xyg4ovyyqnud5aell3tmuhsvyxiikon3ruq.arweave.net/MbRbk-4InC7im_3wbjq4xBtB9ARa9zZQ8q4uhCnN3Gk",
  },
  ced814731c394cf65936379da5cfcc59bea615decd08ca33c5a37359b64e6412: {
    name: "CyberPunkApes #036\n\nComment a word that begins with 's' to get this NFT",
    img: "https://ls63dnun53fn74ipwly57lxlmddupxhed5e6hucv2dnpfq343npa.arweave.net/XL2xto3uyt_xD7Lx367rYMdH3OQfSePQVdDa8sN8214",
  },
  cd008ea18bbfcd5715c38bec598bac0584bc8b1606a63ec4558c103cab9f6098: {
    name: "CyberPunkApes #037\n\nComment a word that begins with 'g' to get this NFT",
    img: "https://hlel4ffhx6av6xppjqev44t2y7ojuks2ihexm3l6spd6lpdo56bq.arweave.net/Osi-FKe_gV9d70wJXnJ6x9yaKlpByXZtfpPH5bxu74M",
  },
  cbae28b5f31a0545e9c3c43133294dfb097d32a43ba089cfad2d09114b29aea6: {
    name: "CyberPunkApes #038\n\nComment a word that begins with 'p' to get this NFT",
    img: "https://e62mkfyt2cokqbjiljse3qtdceadrfuumk5okmxflvgtk3cayq7a.arweave.net/J7TFFxPQnKgFKFpkTcJjEQA4lpRiuuUy5V1NNWxAxD4",
  },
  "19c24fe38520fbfa8f8fcd39d34bc0b980f064e8eb1f842017fd2896bc2f5706": {
    name: "CyberPunkApes #039\n\nComment a word that begins with 'v' to get this NFT",
    img: "https://7s64cusr45frexkzsook5sdwn63tv4abftzhhl4onyinxvtr5ama.arweave.net/_L3BUlHnSxJdWZOcrsh2b7c68AEs8nOvjm4Q29Zx6Bg",
  },
  "4fba3b06f2d3818f08758d0878e681240f4648c91ef65d4e73fc522349b2e687": {
    name: "CyberPunkApes #041\n\nComment any word that starts with 'u' to get this NFT",
    img: "https://rdkxtxzjid5xyoosc34at4hojj45m3qcjxk2ypqf5q3qwt4jm5ha.arweave.net/iNV53ylA-3w50hb4CfDuSnnWbgJN1aw-Bew3C0-JZ04",
  },
  "14e1f6322c21433d0a59fb33a2e761c663beb35b8d191d1d89d4dcb4f682e8e7": {
    name: "CyberPunkApes #042\n\nComment any word that starts with 'w' to get this NFT",
    img: "https://aNP6h2ZOeVtOpaUU-2kBM_rczJezUQ8ktPWcxFZrJ1ww5RxtQFAdtbXp991BhS3.arweave.net/ZbB1z9ybi1Az1Alq0ztMoU0w3MLWRAJtzX392q1V3Yk",
  },
  a5fc13626d8a463a288ecced933b8fdf89b1a8489dfe1b6450c91b8afddea8f7: {
    name: "CyberPunkApes #043\n\nComment any word that starts with 'e' to get this NFT",
    img: "https://6gvjuwrcifop3j7fde3h25fv66evwlux35qoqxir4irgz36skkma.arweave.net/8aqaWiJBXP2n5Rk2fXS194lbLpffYOhdEeIibO_SUpg",
  },
  "3fbdcd120f83c0ad6c7ca12ef66806de981b3a605c65217149b9dc222799b69e": {
    name: "CyberPunkApes #044\n\nComment any word that starts with 'o' to get this NFT",
    img: "https://aNP6h2ZOeVtOpaUU-2kBM_rczJezUQ8ktPWcxFZrJ1ww5RxtQFAdtbXp991BhS3.arweave.net/wpQv6ZVBgIjT7ZFGP3KpNF_NMqpgDpgrc9W_PWL0i-A",
  },
  "9e0da602646511a9220b5cb766c4762bafc9ad0ad812c63fcd3b5dec9d587d61": {
    name: "CyberPunkApes #045\n\nComment any word that starts with 'o' to get this NFT",
    img: "https://aNP6h2ZOeVtOpaUU-2kBM_rczJezUQ8ktPWcxFZrJ1ww5RxtQFAdtbXp991BhS3.arweave.net/qyd0WZ6JsyIo0bSrTqW-0CIneq1XsLLrZbVBgNzxFBo",
  },
  "4dcc86ce4570c6dd5b5ca36067294fa0dd3a211311899eef9f9b1833a3a91665": {
    name: "CyberPunkApes #046\n\nComment any word that starts with 't' to get this NFT",
    img: "https://dh7fkicybvpgdllsqfnseheo7s5f2njlowp6b2cv3hztai4tqf6a.arweave.net/Gf5VIFgNXmGtcoFbIhyO_LpdNSt1n-DoVdnzMCOTgXw",
  },
  "5830c3d914f352b359b293dafc5de5a15abfaa615893466f25c9d9c0d5d0d08a": {
    name: "CyberPunkApes #047\n\nComment any word that starts with 'q' to get this NFT",
    img: "https://aNP6h2ZOeVtOpaUU-2kBM_rczJezUQ8ktPWcxFZrJ1ww5RxtQFAdtbXp991BhS3.arweave.net/oQ7tz9-ZvPWTJz-rwmlfK2bmFB1rSdwy5KdnbAfX8II",
  },
  "95582fb6f83a5e8acb54e5b6c7368c090c1fe2d312648de076e8f06401fbc1fb": {
    name: "CyberPunkApes #048\n\nComment any word that starts with 't' to get this NFT",
    img: "https://aNP6h2ZOeVtOpaUU-2kBM_rczJezUQ8ktPWcxFZrJ1ww5RxtQFAdtbXp991BhS3.arweave.net/XT-fBbp8b4ySMfJdKFScUltt7T7b_L1QWD_QKZl0BUA",
  },
  "3bbad968c6cd9aefded52709fd60f1d309108003af9e635fcfc3efd2b198e23d": {
    name: "CyberPunkApes #049\n\nComment any word that starts with 'v' to get this NFT",
    img: "https://44kkq7ln2wxj5ca6ddeni5grs7agraverbjfqfoz5rmghbsh3ofa.arweave.net/5xSofW3Vrp6IHhjI1HTRl8BogqSIUlgV2exYY4ZH24o",
  },
  d5c0067fe7b5710b2fc9ef5cccdc60e08fadad8aaa778c552e4152f712ef63f4: {
    name: "CyberPunkApes #051\n\nComment a word that starts with 'u' to get this NFT",
    img: "https://imeax7h5qjoxknw62ojz2cfn7yyuvofeqblstzzhaus2ihmxfnfq.arweave.net/QwgL_P2CXXU23tOTnQit_jFKuKSAVynnJwUlpB2XK0s",
  },
  "3f60abef9243745bcb2aafeb461f11dd87af8e3c0c8868d44fb68008ae7b3314": {
    name: "CyberPunkApes #052\n\nComment a word that starts with 'r' to get this NFT",
    img: "https://bx2t3amospyawlalt6gkh62ya2655lpp4cxgi7sjotki3t6qhjna.arweave.net/DfU9gY6T8AssC5-Mo_tYBr3ere_grmR-SXTUjc_QOlo",
  },
  "49b0e921a9bac5f481af744c5f9db7ae8ec54d0b5d0e7a7b2fde9c7303c5c6cc": {
    name: "CyberPunkApes #053\n\nComment a word that starts with 'e' to get this NFT",
    img: "https://3e4tqpfb4nvdo5nnm3pss6d36cq3u4iwazse6e5d5lafpmc5apxa.arweave.net/2Tk4PKHjajd1rWbfKXh78KG6cRYGZE8To-rAV7BdA-4",
  },
  "1fbe38dfad4f99579b5fcf3fedc32e66c9d392b489aa5945c514e0e02a7563c0": {
    name: "CyberPunkApes #054\n\nComment a word that starts with 'k' to get this NFT",
    img: "https://YZ7iceY_z4YqwNEzL6OpkF_V-3lrvjNB_Gn3b6luU9Z725IcJKDSymnAnPmYBJn.arweave.net/XTUFmye2901DXu6SVyaC9btVNao3AU8cFDdf_q2aApo",
  },
  b1b11d7a17155a6f740c5615b6e377880478a28fc5f1cde5db1e4904c698a9d1: {
    name: "CyberPunkApes #055\n\nComment a word that starts with 'g' to get this NFT",
    img: "https://e73z6xfhamrowzesni5gcs534q3b7evxy62cks5xtcrsymstih3a.arweave.net/J_efXKcDIutkkmo6YUu75DYfkrfHtCVLt5ijLDJTQfY",
  },
  a75b1fc9e0b0971bd185cef4780612af6659772953acc20acbdfadd300cbf09e: {
    name: "CyberPunkApes #056\n\nComment a word that starts with 'i' to get this NFT",
    img: "https://YZ7iceY_z4YqwNEzL6OpkF_V-3lrvjNB_Gn3b6luU9Z725IcJKDSymnAnPmYBJn.arweave.net/1h6LpB6FZk24mqMn7eZPoYL6v8vwNnAS_ntYFTs2Snk",
  },
  e0039ec78ee598516a636e43d880f528bac0e9ef90a219423f300ba8e0061650: {
    name: "CyberPunkApes #057\n\nComment a word that starts with 'y' to get this NFT",
    img: "https://YZ7iceY_z4YqwNEzL6OpkF_V-3lrvjNB_Gn3b6luU9Z725IcJKDSymnAnPmYBJn.arweave.net/OY-ZuzYWPT93w_v0cKfWHicrIQF6PZyFQjWi31W9eA8",
  },
  ab6476cd679654fdc8dc1d767bed84c1549044045688b7534d8acb6ab3a82483: {
    name: "CyberPunkApes #058\n\nComment a word that starts with 'd' to get this NFT",
    img: "https://bm2cut46lvxrebc5zdltexecguwdmi6ymer5wmmehk7t746gd46a.arweave.net/CzQqT55dbxIEXcjXMlyCNSw2I9hhI9sxhDq_P_PGHzw",
  },
  "4c99778021b7a9cdd348b9f9a64a09ca8628f67a02f510cc92cfce2abc0fac0d": {
    name: "CyberPunkApes #059\n\nComment a word that starts with 'b' to get this NFT",
    img: "https://rrqgtz3tltmgtgxzm6yzmvfwndnmjunn3rgtqcm6bc46cbew5vpa.arweave.net/jGBp53Nc2Gma-WexllS2aNrE0a3cTTgJngi54QSW7V4",
  },
  "616ffafd4bf9eb565b46d93416f73e189af49763eca8f7a601a0833cf858b4d0": {
    name: "CyberPunkApes #061\n\nComment with a word that starts with 'd' to get this NFT",
    img: "https://luy3mfit4wherd3725jo4j54m3jt7znposce7xemoxhwrhemedjq.arweave.net/XTG2FRPljkiPf9dS7ie8ZtM_5a90hE_cjHXPaJyMINM",
  },
  "555a129d4b3f4eb6ca7b964416605c88267c673ff94227d43ccd5549e64dc796": {
    name: "CyberPunkApes #062\n\nComment with a word that starts with 'u' to get this NFT",
    img: "https://pF9nbGzexdPtm-NF028QD0kOw0J9NLPk3rnQKkodqZOCOLL38J2ekqtKMdSlPvf.arweave.net/4H1iaEmAJ5Vv9tPbESQla7ey6myMfWy7eRxD-JOxTTY",
  },
  c8a9d7af9b7cab1e910673923486304b4554a37157ad2d4289816276cd3e033e: {
    name: "CyberPunkApes #063\n\nComment with a word that starts with 'j' to get this NFT",
    img: "https://os4tbbrmsxa4td2lbpozq7ezdpbdanc2vuhzmtv34gqadtqawrga.arweave.net/dLkwhiyVwcmPSwvdmHyZG8IwNFqtD5ZOu-GgAc4AtEw",
  },
  e1078969c4103d2377ba9759b12d4dbf5342365b429f188b42012c41415ed050: {
    name: "CyberPunkApes #064\n\nComment with a word that starts with 'o' to get this NFT",
    img: "https://pF9nbGzexdPtm-NF028QD0kOw0J9NLPk3rnQKkodqZOCOLL38J2ekqtKMdSlPvf.arweave.net/1wS0iNZxN7qbnAUroyD3PmKp99OwB7w_EuRIf4mKTks",
  },
  "462cfb54376f045bcdf95b8b3c647297fb03919ad5439f2ec8a4dceeb04298f8": {
    name: "CyberPunkApes #065\n\nComment with a word that starts with 'r' to get this NFT",
    img: "https://txsjjx3opay5gesn3omfpjl2kf2c2ypdqjna4ndeagqzebhdybka.arweave.net/neSU3254MdMSTduYV6V6UXQtYeOCWg40ZAGhkgTjwFQ",
  },
  "8e23cebd9ffa1effe0c6345d7a1f7063b4d77ed2a0c10dacfbc006510c47d73d": {
    name: "CyberPunkApes #066\n\nComment with a word that starts with 'z' to get this NFT",
    img: "https://japgp2yvg6nvjjdghcnqjiqgqv2luzwuxikltjllkrmgfyjqjvba.arweave.net/SB5n6xU3m1SkZjibBKIGhXS6ZtS6FLmla1RYYuEwTUI",
  },
  "2298e051237a8b831aa27d4748d759a8002dd1ab448195ae89d888446ee444e3": {
    name: "CyberPunkApes #067\n\nComment with a word that starts with 'q' to get this NFT",
    img: "https://pF9nbGzexdPtm-NF028QD0kOw0J9NLPk3rnQKkodqZOCOLL38J2ekqtKMdSlPvf.arweave.net/1OmP1pJaloAPnyCCA6MX2P36MlL1BBYR6YPm6DAakBg",
  },
  "845d151b104e4933d65868f8192a14032f8c598f5fc9061fc20c26f66847ed8d": {
    name: "CyberPunkApes #068\n\nComment with a word that starts with 'u' to get this NFT",
    img: "https://pbhuolnjpjnnlyzj54uypgl7272rk2tbu5uckxac7ipawehretfa.arweave.net/eE9HLal6WtXjKe8ph5l_1_UVamGnaCVcAvoeCxDxJMo",
  },
  "1bd97b04d31a2c6651da402ed8652b9a2025532eaaaa70229e9d4b946c70a9c8": {
    name: "CyberPunkApes #069\n\nComment with a word that starts with 'a' to get this NFT",
    img: "https://pF9nbGzexdPtm-NF028QD0kOw0J9NLPk3rnQKkodqZOCOLL38J2ekqtKMdSlPvf.arweave.net/6_tFaIfWzby7ZqFTDrFtl_noMEl_EQAzXM3uIzYjOFc",
  },
  "2fed88bd49808b5012f7983f537fd0d4a2ec20c0d7714438224140470bce34b5": {
    name: "CyberPunkApes #071\n\nComment with a word that starts with 'f' to get this NFT",
    img: "https://jwqbbztnymsqnihpfvwbs4dfm6xctzwq3jeqvu25apztvlj5rqda.arweave.net/TaAQ5m3DJQag7y1sGXBlZ64p5tDaSQrTXQPzOq09jAY",
  },
  fbd61b895b853917754ac6754e83ee971c30e22494020b71a844cf94b10b4d82: {
    name: "CyberPunkApes #072\n\nComment with a word that starts with 'f' to get this NFT",
    img: "https://fsy425cpmz6j22npnsjvecetlcuwu2evwzfpbwf4v7vuxey5l4eq.arweave.net/LLHNdE9mfJ1pr2yTUgiTWKlqaJW2SvDYvK_rS5MdXwk",
  },
  "8c45a896b63b96b83aaee0d111b44ea78ea4faea9f627fcd785bd7e64b2dda3b": {
    name: "CyberPunkApes #073\n\nComment with a word that starts with 'i' to get this NFT",
    img: "https://pF9nbGzexdPtm-NF028QD0kOw0J9NLPk3rnQKkodqZOCOLL38J2ekqtKMdSlPvf.arweave.net/Kl2DztX2X0bK-XZA9VDD6UfEHUELFyz-urX_whAV7XI",
  },
  "18738b53cc7e080b0e44d2229d4d1f9222d2bf65bf94a996f2954c2923bfa43f": {
    name: "CyberPunkApes #074\n\nComment with a word that starts with 'a' to get this NFT",
    img: "https://3bqjk6wcislfegy5i7mppwrg6xkhjnwq6u7tdyemhnvudmu6hzbq.arweave.net/2GCVesJEllIbHUfY99om9dR0ttD1PzHgjDtrQbKePkM",
  },
  b3b718338ef9d87c900f33455004d538a92cf947c946f482ed60af73617d3e41: {
    name: "CyberPunkApes #075 || World Cup Special\n\nComment with a word that starts with 'n' to get this NFT",
    img: "https://ifzeyrlavvfuy5tlldqyn2y7wi6ocxp4rlxduq7pjulvnw2pvxda.arweave.net/QXJMRWCtS0x2a1jhhusfsjzhXfyK7jpD700XVttPrcY",
  },
  "892334ecdee077ede49d5fae19334159936835cf0854f3dd5ae76a804bfd6b08": {
    name: "CyberPunkApes #076 || World Cup Special\n\nComment with a word that starts with 'f' to get this NFT",
    img: "https://75rh7y6pfrtnh4bq44ovfwaq2netj56qmtlld5xql6nzfqox3y3a.arweave.net/_2J_488sZtPwMOcdUtgQ00k099Bk1rH28F-bksHX3jY",
  },
  "3989842dcc1219040893d11272c45f75c92e9b8fdba08b4b80573b616dfae471": {
    name: "CyberPunkApes #077\n\nComment with a word that starts with 'i' to get this NFT",
    img: "https://c3jj5honks3kdcn3ulivsm2bm5w34nc4tdzyxmk7oyaqe36dw7va.arweave.net/FtKenc1UtqGJu6LRWTNBZ22-NFyY84uxX3YBAm_Dt-o",
  },
  "8a6c94213a07ce18dab6f93d45f83a9dd152b18c6bfd63b07da09c40b726d4b9": {
    name: "CyberPunkApes #078\n\nComment with a word that starts with 'q' to get this NFT",
    img: "https://atcjee7tofu7a2xhvengk7da6optmaarlqd4bwuos3mr6ejxc7ua.arweave.net/BMSSE_NxafBq56kaZXxg8582ABFcB8DajpbZHxE3F-g",
  },
  "7eae1b94b71f79032549d8be2b49f1fd48812bb843a1b4a1cc1d2e50f083eac4": {
    name: "CyberPunkApes #079\n\nComment with a word that starts with 'e' to get this NFT",
    img: "https://wh55zwwdzsiofvi43e6hgm3bd3go2jp7xvns2c4wo65fswignbgq.arweave.net/sfvc2sPMkOLVHNk8czNhHsztJf-9Wy0Llne6WVkGaE0",
  },
  "034f506a8c42f7cb9791d5bfef1d7b2ef30cbd7a687f005b5cde62c33f4de42c": {
    name: "CyberPunkApes #081\n\nComment with a number that starts with 4 to get this NFT",
    img: "https://vrf2gnm7b5pqjayfbax7py3psdqwlf4z5osh6roh34ts5vea2j5q.arweave.net/rEujNZ8PXwSDBQgv9-NvkOFll5nrpH9Fx98nLtSA0ns",
  },
  "581379bf6793cd5b0df2c016599303c1c5f31fb0bd395f98c37f5a7ffccc3336": {
    name: "CyberPunkApes #082\n\nComment with a number that starts with 3+5 to get this NFT",
    img: "https://2wyvwvfpdv77sdw2glgz33ov5dwom3vtref57auyt5uou44ggzca.arweave.net/1bFbVK8df_kO2jLNne3V6OzmbrOJC9-CmJ9o6nOGNkQ",
  },
  "8511009fec0c8c5bd75bdbfdb8b784c2b01303932d8b131140cf01a1ac34eae8": {
    name: "CyberPunkApes #083\n\nComment with a number that starts with 9-3 to get this NFT",
    img: "https://kqohnvwrr7fujkyc73yl45wwxypfa3k3eeby6yije33wasp6iska.arweave.net/VBx21tGPy0SrAv7wvnbWvh5QbVshA49hCSb3YEn-RJQ",
  },
  e25cafe7be052a963e0f26bee975fa72bebaccc39bd2941a1a4a15df92bc1f7b: {
    name: "CyberPunkApes #084\n\nComment with a number that starts with 12/6 to get this NFT",
    img: "https://qbyhoewh5zl5x5kwnvuxy2qha76hxfn3trrpijrx4mctvw2rs4jq.arweave.net/gHB3EsfuV9v1Vm1pfGoHB_x7lbucYvQmN-MFOttRlxM",
  },
  c6e661875b1f55172052401af0e2cb82e5ab116ae13d5c85823dfcd5e416d4e0: {
    name: "CyberPunkApes #085\n\nComment with a number that starts with 2*2 to get this NFT",
    img: "https://iXIp88uk_ywfgZ6b8x76MvFedivAohjqWCiyNgvLE85gPIwyXiKUMItoERo6bGJ.arweave.net/2dgA6YtYeMu9DTrqkKPVHLDgz3ezKPBDP_rVhhYJlTQ",
  },
  "1183d20038638a36128e0fa421955aa83ffb0db2fb4d963ddaa4aa941722d547": {
    name: "CyberPunkApes #086\n\nComment with a number that starts with 8/8 to get this NFT",
    img: "https://Nk4SkSMclKj_6xIHsDcWJkMKpLhy2P9xQGYOaBhi-A8TbyT7Hh7PPrA8xMTNhol.arweave.net/1QpB8JdXKLtsDPWEJQgFsojpNRm5VjVMzzEaqAyvzR8",
  },
  "0eed491fc66267fca18618a062750b66f71dbf9265efc75a5e45164f6a98bb09": {
    name: "CyberPunkApes #087\n\nComment with a number that starts with 18/3 to get this NFT",
    img: "https://Nk4SkSMclKj_6xIHsDcWJkMKpLhy2P9xQGYOaBhi-A8TbyT7Hh7PPrA8xMTNhol.arweave.net/G596hoP_RLqHX-S4d3qGU2eowQvJ7kvBKgJPqK5H_sE",
  },
  "3b946d3e8397a4d7e967406987f004cb0583e757ece66d3dd9a1801bc750eb05": {
    name: "CyberPunkApes #088\n\nComment with a number that starts with 10/5 to get this NFT",
    img: "https://4ilvhtdhzqakreezavf7ztyidomeejklnce2akqtmn7vfanrnifa.arweave.net/4hdTzGfMAKiQmQVL_M8IG5hCJUtoiaAqE2N_UoGxago",
  },
  fd2201f964d8356aef9a5e7f00413ff614be9b6775bf70a9ba330383d90083c9: {
    name: "CyberPunkApes #089\n\nComment with a number that starts with 18/3 to get this NFT",
    img: "https://ygbmzfnmibdumk7g6dqfannslmcly3lypceqi7sgcdju5yojwq4q.arweave.net/wYLMlaxAR0Yr5vDgUDWyWwS8bXh4iQR-RhDTTuHJtDk",
  },
  "10c86d09debc5205ee2caa4c3083e3bc7bd4234c82288aee6974b263ca319e1a": {
    name: "CyberPunkApes #091\n\nComment with a word starting with 'k' to get this NFT",
    img: "https://Nk4SkSMclKj_6xIHsDcWJkMKpLhy2P9xQGYOaBhi-A8TbyT7Hh7PPrA8xMTNhol.arweave.net/0QlszAa_W6r-oC6eOO2x3XLsdUICD-YMW1CH0uGweZQ",
  },
  fc4d9fecc9d0c7187c13dfb6c7aab5d057c3fcc7d3d607da597e371665286d5e: {
    name: "CyberPunkApes #092\n\nComment with a number starting with 4+1 to get this NFT",
    img: "https://Nk4SkSMclKj_6xIHsDcWJkMKpLhy2P9xQGYOaBhi-A8TbyT7Hh7PPrA8xMTNhol.arweave.net/GCiVBeJcj5sD6_SAeCpY8jiqVvrBpze4mz15N3-SOx0",
  },
  "69277acf3737488d2d3a0b5b9b9d49727523c1045f9eb3f7eadb765f16893e93": {
    name: "CyberPunkApes #093\n\nComment with a number starting with 4+5 to get this NFT",
    img: "https://kwa6tggwrmrk7dxnhv6edgc4svctsa3ns357pm6cnf4a6zt7bamq.arweave.net/VYHpmNaLIq-O7T18QZhclUU5A22W-_ezwml4D2Z_CBk",
  },
  "759184f3b57935bc90bd4a79854c44021a88067f99eb4061435f5c5fd7fdf7a2": {
    name: "CyberPunkApes #094\n\nComment with a number starting with 4+3 to get this NFT",
    img: "https://Nk4SkSMclKj_6xIHsDcWJkMKpLhy2P9xQGYOaBhi-A8TbyT7Hh7PPrA8xMTNhol.arweave.net/92fT5iz42-k1Px66Byuz7HgUvYa6diprerjb_uXm3iE",
  },
  c2a577c6a1f8d21be11d7caed3ad57f780f2654845b2820284d03290acdb9534: {
    name: "CyberPunkApes #095\n\nComment with a number starting with 2+7 to get this NFT",
    img: "https://42zyxptsr2gvxhdmx3phpnvi65rdzyriblu4r76vmwdvtccjfqoa.arweave.net/5rOLvnKOjVucbL7ed7ao92I84igK6cj_1WWHWYhJLBw",
  },
  "2f6fe57995b49214b3aff964ac1f62c09c3da0a33d4560fc5a348053b9745ee8": {
    name: "CyberPunkApes #096\n\nComment with a number starting with 7-3 to get this NFT",
    img: "https://gcgdvuc53uxlwct2jrotvsx56s7ecx3yqrotjjd2wmmz6g3kloaa.arweave.net/MIw60F3dLrsKekxdOsr99L5BX3iEXTSkerMZnxtqW4A",
  },
  "875ab442d983844d5d8905c02c709e888f4e6117d3978b927bea7d4cec0ed539": {
    name: "CyberPunkApes #097\n\nComment with a number starting with 5-4 to get this NFT",
    img: "https://Nk4SkSMclKj_6xIHsDcWJkMKpLhy2P9xQGYOaBhi-A8TbyT7Hh7PPrA8xMTNhol.arweave.net/38565wMynwYsmnfpLWJfYptSpAC1688Y6xQMRMUOSfo",
  },
  c520813cf48e7d5b892d9489a487f220c5476d2d59a9211767ff36c76c8a7f34: {
    name: "CyberPunkApes #098\n\nComment with a number starting with 8-1 to get this NFT",
    img: "https://Nk4SkSMclKj_6xIHsDcWJkMKpLhy2P9xQGYOaBhi-A8TbyT7Hh7PPrA8xMTNhol.arweave.net/1l6K6moazAnBPDaWRtvxfqzpFn3x0By9uB39GXnZIPU",
  },
  "6f601b11760c70ba0ce6be9cdc5a857ee9b9efd34c86eb927d9292c135118400": {
    name: "CyberPunkApes #099\n\nComment with a number starting with 99-99 to get this NFT",
    img: "https://yupx6izyibw6yh5zw6k2l2x4zphqkhddix3qfcsggjpt5a5zz4va.arweave.net/xR9_IzhAbewfubeVper8y88FHGNF9wKKRjJfPoO5zyo",
  },
};

export { values, originals };
