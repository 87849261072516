import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Deso from "deso-protocol";

function Gen2Bid(props) {
  const deso = new Deso();
  const navigate = useNavigate();

  async function initGen2Bid() {
    const res = await fetch(
      "https://node.deso.org/api/v0/get-posts-for-public-key",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          PublicKeyBase58Check: "",
          Username: "CyberPunkApes",
          ReaderPublicKeyBase58Check:
            "BC1YLhM4vH9FTepmEm9jCBrKmR46vW5n517YEopsuLT49JQHw674KE2",
          LastPostHashHex: "",
          NumToFetch: 250,
          MediaRequired: true,
        }),
      }
    );
    const data = await res.json();
    let posts = data["Posts"];
    // Reverse posts
    posts = posts.reverse();
    // Loop through posts
    for (let i = 0; i < posts.length; i++) {
      const postextraData = posts[i]["PostExtraData"];
      const isGen2 = postextraData.Generation;
      if (isGen2 === "2") {
        navigate(`/gen2/${posts[i].PostHashHex}`);
      }
    }
  }

  useEffect(() => {
    initGen2Bid();
  }, []); // Empty dependency array ensures this runs only once

  return (
    <div>
      {/* Loader */}
      <div className="loader h-screen bg-[#150026]">
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-red-500"></div>
        </div>
      </div>
    </div>
  );
}

export default Gen2Bid;
