import React from "react";
import Navbar from "../../Components/Navbar";

export default function Dashboard() {
  return (
    <div>
      <Navbar showLogin={true} />
      <div className="pt-32 sm:pt-24 bg-[#150026]">

      </div>
    </div>
  );
}
