import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Deso from "deso-protocol";
import toast, { Toaster } from "react-hot-toast";
import logo from "../../assets/logo.png";
import Navbar from "../../Components/Navbar";
import { values } from "../../tools/constants";
import { originals } from "../../tools/constants";
import DerivedKeyModal from "../../Components/Modals/DerivedKeyModal";
import { rule } from "postcss";
export default function Gen2() {
  const params = useParams();
  const nftHashHex = params.nftHashHex;
  const [nftInfo, setNftInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [tab, setTab] = useState("bids");
  const [properties, setProperties] = useState(null);

  const [unstakbles, setUnstakbles] = useState(null);
  const [stakableInfo, setStakableInfo] = useState(null);
  const deso = new Deso();
  const [loggedInUser, setLoggedInUser] = useState(false);
  const [selectedForStake, setSelectedForStake] = useState([]);

  const [amountToBid, setAmountToBid] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [username, setUsername] = useState("");

  const [alreadyStaken, setAlreadyStaken] = useState(false);
  const [bidString, setBidString] = useState("");
  const [expiryTimestamp, setExpiryTimestamp] = useState(0);
  const [timeLeft, setTimeLeft] = useState(0);
  const [desoHolding, setDesoHolding] = useState(0);

  const rules = `1. You will need to stake 25 Gen1 NFTs to bid on Gen2.\n
  2. Minimum bid starts at 1 $DESO\n
  3. If you win the bid, all your 25 G1s will be burned and you will get the NFT\n
  4. If you loose the bid, only 3 of your G1s will be burned and you will share a royalty of 4%. The ratio of royalty will be based on amount of $DeSo you bid`;

  async function initNFT(loginKey, unstakableList) {
    const requestPayload = {
      PublicKeyBase58Check: loginKey,
    };
    const responseUser = await deso.user.getSingleProfile(requestPayload);
    setUsername(responseUser.Profile.Username);

    setDesoHolding(responseUser.Profile.DESOBalanceNanos / 1e9);
    const request = {
      PostHashHex: nftHashHex,
      FetchParents: true,
      CommentOffset: 0,
      CommentLimit: 20,
      AddGlobalFeedBool: false,
      ThreadLevelLimit: 2,
      ThreadLeafLimit: 1,
      LoadAuthorThread: true,
    };
    const response = await deso.posts.getSinglePost(request);
    try {
      if (!(response.PostFound.PostExtraData.Generation === "2")) {
        //load to first page
        window.location.href = "/";
      }
    } catch {
      //load to first page
      window.location.href = "/";
    }
    

    setNftInfo(response.PostFound);

    let propValue = response.PostFound.PostExtraData;
    //remove keys named "App", "Edition", "Generation", "Language", "Node"
    delete propValue.App;
    delete propValue.Edition;
    delete propValue.Generation;
    delete propValue.Language;
    delete propValue.Node;

    setProperties(propValue);
    const expiryTime = response.PostFound.TimestampNanos / 1e9 + 200*24* 60 * 60;
    setExpiryTimestamp(expiryTime);
    findBid(response.PostFound.Comments);
    const tl = Math.round(expiryTime - Date.now() / 1000);

    setTimeLeft(tl);
    if (tl < 0) {
      setIsLoading(false);
      return;
    }

    if (loginKey) {
      const res2 = await fetch("https://talkbuddy.in/get-ape-by-owner", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          owner: loginKey,
          limit: 200,
        }),
      });
      const data = await res2.json();
      if (data.status == 200) {
        const apes = data.apeInfo;
        let listOfStakables = [];
        //loop thourgh apes and check if the key starts unstkables
        Object.keys(apes).map((ape) => {
          const postHashHex = ape.split("-")[0];
          const serial = ape.split("-")[1];
          //convert serial to number
          const serialNum = parseInt(serial);
          if (!unstakableList.includes(postHashHex) && serialNum > 5) {
            listOfStakables.push(ape);
          }
        });
        setStakableInfo(listOfStakables);

        const res2 = await fetch("https://talkbuddy.in/get-stake-status", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            publicKey: loginKey,
          }),
        });
        const data2 = await res2.json();
        if (data2.stakeStatus.length > 0) {
          setSelectedForStake(data2.stakeStatus);
          setAlreadyStaken(true);
        } else {
          //insert the first 25 keys of listOfStakables into selectedForStake
          const temp = listOfStakables.slice(0, 25);

          setSelectedForStake(temp);
        }
      }
    }
    setIsLoading(false);
  }

  function findBid(commentList) {
    //loop through commentList and set bidString to the body of the comment if body starts with Current Bids:
    Object.keys(commentList).map((comment) => {
      if (commentList[comment].Body.startsWith("Current Bids:")) {
        setBidString(commentList[comment].Body);
        return;
      }
    });
  }
  function readableTimeLeftFunc(timeLeft) {
    const daysLeft = Math.floor(timeLeft / (24 * 60 * 60));
    const hoursLeft = Math.floor((timeLeft % (24 * 60 * 60)) / (60 * 60));
    const minutesLeft = Math.floor((timeLeft % (60 * 60)) / 60);
    const secondsLeft = timeLeft % 60;

    const parts = [];

    if (daysLeft > 0) {
      parts.push(`${daysLeft} day${daysLeft > 1 ? "s" : ""}`);
    }
    if (hoursLeft > 0) {
      parts.push(`${hoursLeft} hour${hoursLeft > 1 ? "s" : ""}`);
    }
    if (minutesLeft > 0) {
      parts.push(`${minutesLeft} min`);
    }
    if (secondsLeft > 0 || parts.length === 0) {
      parts.push(`${secondsLeft} second${secondsLeft > 1 ? "s" : ""}`);
    }

    return parts.join(" ");
  }

  useEffect(() => {
    const loggedInPublicKey = localStorage.getItem("loggedInUser");
    console.log(loggedInPublicKey);
    if (loggedInPublicKey) {
      //const loggedInUser = await deso.getUser(loggedInPublicKey);
      setLoggedInUser(loggedInPublicKey.toString());
    } else {
      return;
    }

    let tempValue = [
      "b3b718338ef9d87c900f33455004d538a92cf947c946f482ed60af73617d3e41",
      "892334ecdee077ede49d5fae19334159936835cf0854f3dd5ae76a804bfd6b08",
    ];
    Object.keys(values).map((key) => {
      tempValue.push(key);
    });

    setUnstakbles(tempValue);

    if (!nftHashHex) return;
    if (nftInfo) return;
    initNFT(loggedInPublicKey, tempValue);
  }, []);

  useEffect(() => {
    if (!expiryTimestamp) return;
    const currentTimeStamp = Math.floor(Date.now() / 1000);
    const tl = Math.round(expiryTimestamp - currentTimeStamp);

    if (tl <= 0) return;

    const timer = setTimeout(() => {
      setTimeLeft(tl - 1);
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft]);

  const handleLogin = async () => {
    const response = await deso.identity.login(2);
    console.log(response);
    if (response) {
      //save public key in local storage
      localStorage.setItem("loggedInUser", response.key);
      setLoggedInUser(response.key);
      //reload page
      window.location.reload();
    }
  };

  const unstakeNFTs = async () => {
    const loadingToast = toast.loading("Unstaking NFTs...");

    const jwt = await deso.identity.getJwt(undefined);
    const res2 = await fetch(
      "https://talkbuddy.in/unstake-and-cancel-cpa-bid",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          jwt: jwt,
          bidderKey: loggedInUser,
          username: username,
          nftHashHexToStakeList: selectedForStake,
          nftHashHexToBid: nftHashHex,
        }),
      }
    );
    const data2 = await res2.json();
    if (data2.didStake) {
      // remove loading toast
      toast.dismiss(loadingToast);
      toast.success("Unstaked NFTs successfully");
      window.location.reload();
    } else {
      // remove loading toast
      toast.dismiss(loadingToast);
      toast.error(data2.remark);
    }
  };

  return (
    <div className="w-full bg-[#150026] overflow-x-hidden min-h-screen">
      <Navbar showLogo={true} showLogin={false} />
      <DerivedKeyModal
        showModal={showModal}
        setShowModal={setShowModal}
        listOfNftHash={selectedForStake}
        bidAmountDeSo={amountToBid}
        loggedInKey={loggedInUser}
        username={username}
        nftHashHexToBid={nftHashHex}
        desoHolding={desoHolding}
      />
      {!loggedInUser && (
        <div className="w-full  min-h-full mt-28 flex justify-center flex-col items-center space-y-3">
          <p className="text-white">Login with DeSo to continue</p>
          <button
            className="bg-green-500 text-white hover:bg-green-600 px-5 py-2 rounded-lg"
            onClick={() => handleLogin()}
          >
            Login with DeSo
          </button>
        </div>
      )}

      {loggedInUser && (
        <div>
          <div className="bg-[#150026]">
            {isLoading && (
              <div className="flex justify-center items-center h-screen mt-2 bg">
                <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-red-500"></div>
              </div>
            )}
          </div>
          <Toaster />
          {nftInfo && (
            <div className="w-full max-w-[1024px] shadow-[#241530] shadow-lg  rounded-3xl mx-auto mt-8">
              <div className="flex flex-col lg:flex-row overflow-visible">
                <div className="relative top-0 left-0 z-10 flex-none w-full lg:w-2/4">
                  <div
                    className={`image w-full border border-purple-800 h-full rounded-3xl sm:rounded-bl-3xl sm:rounded-tl-3xl flex flex-col items-center justify-start p-4`}
                  >
                    {true && (
                      <>
                        <div
                          style={{
                            backgroundImage: `url(${nftInfo.ImageURLs[0]})`,
                            filter: "blur(3px)",
                            opacity: ".2",
                            backgroundRepeat: "no-repeat",
                          }}
                          className="w-full h-full backdrop-xl backdrop-blur-md p-4 absolute top-0 left-0 rounded-bl-3xl rounded-tl-3xl "
                        />

                        <img
                          className="rounded-3xl shadow-purple-800  w-full object-cover z-10"
                          alt="CyberPunkApes"
                          src={nftInfo.ImageURLs[0]}
                        />
                      </>
                    )}
                  </div>
                </div>
                <div className="content flex flex-col w-full lg:w-2/4 pt-8 pb-4">
                  <div>
                    <div className="flex justify-between border-b-2 pb-4 border-purple-900  px-8">
                      <p className="text-white text-2xl font-bold">
                        {nftInfo.Body.split("\n").slice(0, 1)}
                      </p>
                      <div className="flex items-center space-x-1 flex-shrink-0"></div>
                    </div>
                  </div>

                  {/* <div className=' text-white mt-8 mb-3'> */}
                  <div className="w-full px-2 justify-center mx-auto flex space-x-4 text-white font-semibold text-lg mt-4">
                    <button
                      className={`${
                        tab === "bids"
                          ? "border-b-4 border-b-[#4f237c]"
                          : "text-gray-400"
                      } hover:text-white pb-2`}
                      onClick={() => setTab("bids")}
                    >
                      Bids
                    </button>
                    <button
                      className={`${
                        tab === "properties"
                          ? "border-b-4 border-b-[#4f237c]"
                          : "text-gray-400"
                      } hover:text-white pb-2`}
                      onClick={() => setTab("properties")}
                    >
                      Properties
                    </button>

                    <button
                      className={`${
                        tab === "rules"
                          ? "border-b-4 border-b-[#4f237c]"
                          : "text-gray-400"
                      } hover:text-white pb-2`}
                      onClick={() => setTab("rules")}
                    >
                      Rules
                    </button>
                  </div>
                  <div>
                    {tab === "properties" && (
                      <div className="flex flex-col  mx-6 mt-4">
                        {properties &&
                          Object.keys(properties).map((key) => {
                            return (
                              <div
                                className="flex   items-center space-x-2"
                                key={key}
                              >
                                <p className="text-white font-bold text-lg">
                                  {key}:
                                </p>
                                <p className="text-white text-lg">
                                  {properties[key]}
                                </p>
                              </div>
                            );
                          })}
                      </div>
                    )}

                    {tab === "bids" && (
                      <div className="flex flex-col  mx-6 mt-4">
                        {bidString && (
                          <div>
                            <p className="text-white font-bold text-lg bidVal">
                              {bidString}
                            </p>
                          </div>
                        )}
                      </div>
                    )}
                    {tab === "rules" && (
                      <div className="flex flex-col  mx-6 mt-4">
                        {rules && (
                          <div>
                            <p className="text-white font-bold text-lg bidVal">
                              {rules}
                            </p>
                          </div>
                        )}
                      </div>
                    )}
                  </div>

                  {/* </div> */}

                  {/* <div className=' my-1 flex flex-col justify-end px-4 '>
                {commentList.map((comment) => {
                  return (
                    <CommentCard
                      key={comment.timestamp}
                      comment={comment}
                      currentUserID={currentUserID}
                      deleteComment={deleteComment}
                    />
                  );
                })}
              </div> */}
                </div>
              </div>
            </div>
          )}

          {!isLoading && nftInfo && (
            <div className="w-full px-2 mx-auto ">
              {timeLeft > 0 && (
                <div className="flex justify-center mt-10">
                  <p className="text-white text-center text-lg">
                    Bid gets over in: {readableTimeLeftFunc(timeLeft)}
                  </p>
                </div>
              )}
              {timeLeft < 0 && (
                <div className="flex justify-center mt-10">
                  <p className="text-white text-center">
                    Bidding has been ended.
                  </p>
                </div>
              )}
              {timeLeft > 0 && selectedForStake.length < 25 && (
                <div className=" px-4 rouned-md  text-white flex justify-center mt-10">
                  <p className="bg-red-600 px-8 rounded-md">
                    Please select 25 NFTs to stake
                  </p>
                </div>
              )}

              {!alreadyStaken && timeLeft > 0 && (
                <div className="flex justify-center mt-10 flex-col">
                  <p className="text-white text-center">
                    Amount DeSo to bid. Minimum Bid: 1 $DESO
                  </p>
                  <div className="flex justify-center">
                    <input
                      type="number"
                      className="sm:w-1/3 w-3/4 border-1 border-[#20072e] rounded-md p-2 outline-none  bg-[#350353] text-white "
                      placeholder="Enter $DESO to Bid"
                      value={amountToBid}
                      onChange={(e) => setAmountToBid(e.target.value)}
                    />
                    <button
                      className="bg-green-500 text-white hover:bg-green-600 px-6 py-2 rounded-md "
                      onClick={() => {
                        if (amountToBid < 1) {
                          toast.error("Minimum Bid is 1 $DESO");
                        } else if (selectedForStake.length < 25) {
                          toast.error("Please select 25 NFTs to stake");
                        } else {
                          setShowModal(true);
                        }
                      }}
                    >
                      Bid and Stake
                    </button>
                  </div>
                </div>
              )}

              {alreadyStaken && timeLeft > 0 && (
                <div className="flex justify-center mt-10 flex-col">
                  <div className="mx-auto">
                    <button
                      className="bg-red-500 hover:bg-red-600 px-6 py-2 rouned-lg text-white"
                      onClick={() => {
                        unstakeNFTs();
                      }}
                    >
                      Cancel bid and Unstake
                    </button>
                  </div>
                </div>
              )}

              {!alreadyStaken && timeLeft > 0 && (
                <p className="text-white text-center mt-6">
                  Stakable Gen1 CyberPunkApes. 25 recommended Apes are already
                  selected.
                </p>
              )}

              {alreadyStaken && timeLeft > 0 && (
                <p className="text-white text-center mt-6">
                  You have staked these NFTs for the Bid!
                </p>
              )}

              {timeLeft > 0 && (
                <div className="grid grid-cols-2 gap-7 text-white my-8 text-sm sm:text-base mt-5">
                  <p className="justify-end flex">Serial</p>
                  <p className="justify-start flex">CyberPunkApe</p>
                </div>
              )}
              {!loggedInUser && (
                <div className="flex justify-center">
                  <button className="">
                    You are not Logged In. Please reload this page if something
                    is wrong{" "}
                  </button>
                </div>
              )}
              {!stakableInfo && loggedInUser && timeLeft > 0 && (
                <div className="flex justify-center">
                  <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-red-500 mt-4"></div>
                </div>
              )}
              {stakableInfo && timeLeft > 0 && (
                <div className="mt-8">
                  {stakableInfo.map((value, index) => (
                    <div
                      className="grid grid-cols-2 gap-7 text-white my-4 text-sm sm:text-base "
                      key={index}
                    >
                      <div className="flex space-x-1 items-center  justify-end">
                        <div className="flex space-x-2">
                          {!alreadyStaken && (
                            <input
                              type="checkbox"
                              onChange={() => {
                                if (selectedForStake.includes(value)) {
                                  setSelectedForStake(
                                    selectedForStake.filter(
                                      (item) => item !== value
                                    )
                                  );
                                } else if (selectedForStake.length < 25) {
                                  setSelectedForStake([
                                    ...selectedForStake,
                                    value,
                                  ]);
                                }
                              }}
                              checked={selectedForStake.includes(value)}
                            />
                          )}
                          {alreadyStaken &&
                            selectedForStake.includes(value) && (
                              <p>{` ${value.split("-")[1]}`}</p>
                            )}
                          {!alreadyStaken && <p>{` ${value.split("-")[1]}`}</p>}
                        </div>
                      </div>
                      {alreadyStaken && selectedForStake.includes(value) && (
                        <div className="flex space-x-1 items-center underline justify-start">
                          <a
                            className="flex space-x-3 items-center  justify-center "
                            href={`https://diamondapp.com/nft/${
                              value.split("-")[0]
                            }`}
                            target="_blank"
                          >
                            <img
                              src={`/CPA_ORIGINALS/${originals[
                                value.split("-")[0]
                              ].name.slice(15, 18)}.jpg`}
                              className="w-9 h-9 sm:w-14 sm:h-14 object-contain rounded-md"
                            ></img>

                            <span>
                              {" "}
                              {originals[value.split("-")[0]].name.slice(
                                15,
                                18
                              )}{" "}
                            </span>
                          </a>
                        </div>
                      )}

                      {!alreadyStaken && (
                        <div className="flex space-x-1 items-center underline justify-start">
                          <a
                            className="flex space-x-3 items-center  justify-center "
                            href={`https://diamondapp.com/nft/${
                              value.split("-")[0]
                            }`}
                            target="_blank"
                          >
                            <img
                              src={`/CPA_ORIGINALS/${originals[
                                value.split("-")[0]
                              ].name.slice(15, 18)}.jpg`}
                              className="w-9 h-9 sm:w-14 sm:h-14 object-contain rounded-md"
                            ></img>

                            <span>
                              {" "}
                              {originals[value.split("-")[0]].name.slice(
                                15,
                                18
                              )}{" "}
                            </span>
                          </a>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
