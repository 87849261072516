import React, { useState, useEffect } from "react";
import Navbar from "../../Components/Navbar";
import Footer from "../Landing/Footer";
import Deso from "deso-protocol";

const Airdrop = () => {
  const deso = new Deso();
  const INFO_POST_HASH_HEX =
    "0f708032979dc801219af6390dc00310913a877c6c394420aa96f7e7a22c9961";

  const INFO_POST_HASH_HEX2 =
    "6175c513005894d871eca62a47d2203efc68c7744a6e09a4a0e0eb5444a29514";

  const INFO__POST_HASH_HEX3 =
    "7120ca8a382365b04275293c204323276879150fd45fe6487a6cd537e646dd4d";
  const [loadedInitialData, setLoadedInitialData] = useState(false);
  const [allNftInfo, setAllNftInfo] = useState([]);
  const [allNftEntries, setAllNftEntries] = useState([]);
  const [totalGen1sBurned, setTotalGen1sBurned] = useState(0);
  const [totalMarketDeso, setTotalMarketDeso] = useState(0);

  const [topOwnersState, setTopOwnersState] = useState({});
  const [totalAirdropValue, setTotalAirdropValue] = useState(0);
  async function getGen2Info() {
    const request = {
      PostHashHex: INFO_POST_HASH_HEX,
    };
    const request2 = {
      PostHashHex: INFO_POST_HASH_HEX2,
    };

    const request3 = {
      PostHashHex: INFO__POST_HASH_HEX3,
    };
    const singlePostInfo = await deso.posts.getSinglePost(request);
    const singlePostInfo2 = await deso.posts.getSinglePost(request2);
    const singlePostInfo3 = await deso.posts.getSinglePost(request3);

    const bodyString = singlePostInfo.PostFound.Body;
    const bodyString2 = singlePostInfo2.PostFound.Body;
    const bodyString3 = singlePostInfo3.PostFound.Body;
    //bodyString is a string of json data. convert to object
    const bodyObject = JSON.parse(bodyString);
    const bodyObject2 = JSON.parse(bodyString2);
    const bodyObject3 = JSON.parse(bodyString3);
    //reverse the keys in bodyObject
    // add the dictionary bodyObject2 to bodyObject
    const finalBodyObject = { ...bodyObject, ...bodyObject2, ...bodyObject3 };
    const reversedKeys = Object.keys(finalBodyObject).reverse();
    //loop through reversedKeys
    const tempNftInfo = [];
    let tempTotalGen1sBurned = 0;
    let tempTotalMarketDeso = 0;
    for (let i = 0; i < reversedKeys.length; i++) {
      const key = reversedKeys[i];
      const value = finalBodyObject[key];
      tempTotalGen1sBurned += value.tb;
      tempTotalMarketDeso += value.ip ? value.ip : 0;

      //push value to allNftInfo
      tempNftInfo.push(value);
    }
    setTotalMarketDeso(tempTotalMarketDeso);
    setTotalGen1sBurned(tempTotalGen1sBurned);
    setAllNftInfo(tempNftInfo);

    console.log(tempNftInfo);

    let topOwnersVar = {};
    //loop through tempNftInfo
    let totalAirdropValue = 0;
    for (let i = 0; i < tempNftInfo.length; i++) {
      const initialPrice = tempNftInfo[i].ip;
      const ownerKey = tempNftInfo[i].ok;
      const ownerName = tempNftInfo[i].on;
      totalAirdropValue += initialPrice === "" ? 1 : parseFloat(initialPrice);

      if (ownerKey === "") {
        continue;
      }

      //if ownerKey is in topOwnersVar
      if (ownerKey in topOwnersVar) {
        //add initialPrice to the value of ownerKey
        topOwnersVar[ownerKey].initialPrice +=
          initialPrice === "" ? 1 : parseFloat(initialPrice);
      } else {
        //add ownerKey to topOwnersVar
        topOwnersVar[ownerKey] = {
          initialPrice: initialPrice === "" ? 1 : parseFloat(initialPrice),
          ownerName: ownerName,
        };
      }
    }
    //sort topOwnersVar by initialPrice in descending order
    topOwnersVar = Object.fromEntries(
      Object.entries(topOwnersVar).sort(
        ([, a], [, b]) => b.initialPrice - a.initialPrice
      )
    );
    console.log(topOwnersVar);

    setTotalAirdropValue(totalAirdropValue);

    setTopOwnersState(topOwnersVar);
    setLoadedInitialData(true);
  }

  useEffect(() => {
    if (!loadedInitialData) {
      getGen2Info();
    }
  }, []);
  return (
    <div className="bg-[#150026]">
      <>
        <Navbar showLogin={true} showLogo={true} />
        <div className="pt-20  bg-[#150026]">
          <div className="  z-20">
            <h1 className="text-4xl text-center text-white font-bold">
              Airdrops for CyberPunkApes Owners.
            </h1>
            <p className="text-white text-center mt-4 text-lg sm:px-8">
              We have been airdropping various things to our holders. Sometimes
              random $DESO coins, other NFTs, royalties, memecoins, etc.
            </p>
            <h2 className="text-2xl text-center text-white font-bold mt-4">
              Past Airdrops
            </h2>

            <ol className="text-white mx-auto mt-4 text-lg sm:px-8 sm:w-1/3 ">
              <li> 1. $DESO coins to random holders </li>
              <li>
                {" "}
                2. 6,942,000,000{" "}
                <a
                  href="https://diamondapp.com/u/lmeow/"
                  className="text-blue-500 hover:text-blue-600 font-semibold"
                >
                  $lmeow
                </a>{" "}
                coins to each CyberPunkApes Owner. Lmeow is a meme coin using
                which one can play various games.{" "}
              </li>
              <li>
                3. 1 DESO, 1 Creator coin of CyberPunkApes, Santa Ape and 25
                Gen1s through{" "}
                <a
                  href="https://diamondapp.com/posts/https://diamondapp.com/posts/a7ab908e1b96ac37ed98fdef60ab5203893432aed9235944ee22c464880d01a1"
                  className="text-blue-500 hover:text-blue-600 font-semibold"
                >
                  @SantaApe
                </a>{" "}
                during 2023 Christmas
              </li>
            </ol>

            <h2 className="text-2xl text-center text-white font-bold mt-4">
              Upcoming Airdrops
            </h2>

            <ul className="text-white text-center mt-4 text-lg sm:px-8">
              <li>
               Locked $FOCUS airdrop to each Gen2 NFT Holder worth the same amount of
                $DESO for which they paid for a Gen2. More info{" "}
                <a
                  href="https://diamondapp.com/posts/4e54951a0f6e9e6f83e79664a74bfe73218921f9971972662504f6c8dcd75551"
                  target="_blank"
                  className="text-blue-500 hover:text-blue-600 font-semibold"
                >
                  here
                </a>
              </li>
            </ul>

            {!loadedInitialData && (
              <div className="flex justify-center items-center h-screen mt-4 bg">
                <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-red-500"></div>
              </div>
            )}

            {loadedInitialData && (
              <div>
                <h1 className="text-4xl text-center text-white font-bold my-7">
                  Leaderboard for $FOCUS Airdrops
                </h1>

                <p className="text-white text-center mt-4 text-lg sm:px-8">
                  Total Airdrop of $FOCUS coins: {totalAirdropValue.toFixed(2)}{" "}
                  $DESO
                </p>
                {Object.keys(topOwnersState).map((key, index) => (
                  <div className="flex justify-center items-center text-white text-lg sm:px-8">
                    <div className="flex items-center text-white text-lg sm:px-8 sm:w-1/4 my-4 space-x-2">
                        <p className="font-semibold">{index + 1}.</p>
                      <img
                        src={`https://diamondapp.com/api/v0/get-single-profile-picture/${key}?fallback=https://diamondapp.com/assets/img/default-profile-pic.png`}
                        className="w-10 h-10 rounded-full"
                      />
                      <p className="font-semibold">
                        {topOwnersState[key].ownerName}
                      </p>
                    </div>
                    <p>{topOwnersState[key].initialPrice.toFixed(2)} $DESO</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        <Footer />
      </>
    </div>
  );
};

export default Airdrop;
