import React, { useState, useEffect } from "react";
import Navbar from "../../Components/Navbar";
import Footer from "../Landing/Footer";
import Deso from "deso-protocol";
import { originals } from "../../tools/constants";
import toast, { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
const Market = () => {
  const deso = new Deso();
  const [isBuying, setIsBuying] = useState(false);

  const [loadedInitialData, setLoadedInitialData] = useState(false);
  const [cheapApeInfo, setCheapApeInfo] = useState([]);
  const [cheapApesOwnerInfo, setCheapApesOwnerInfo] = useState([]);

  const [originalPunks, setOriginalPunks] = useState(originals);
  async function getCheapApes() {
    const response = await fetch("https://talkbuddy.in/get-buyNow-punks", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        limit: 300,
        order: "ascending",
      }),
    });
    const data = await response.json();
    if (data.status === 200) {
      //create a list of cheapApes sorted buy buyNowPrice
      let cheapApeList = [];
      for (const key in data.buyNowPunks) {
        let newDict = {
          buyNowPrice: data.buyNowPunks[key].buyNowPrice,
          owner: data.buyNowPunks[key].owner,
          minBidDeso: data.buyNowPunks[key].minBidDeso,
          buyNowPrice: data.buyNowPunks[key].buyNowPrice,
          postHashHex: key.split("-")[0],
          serialNumber: key.split("-")[1],
        };

        cheapApeList.push(newDict);
      }
      cheapApeList.sort((a, b) => a.buyNowPrice - b.buyNowPrice);

      setCheapApeInfo(cheapApeList);
      let userListUnique = [];
      for (const key in data.buyNowPunks) {
        userListUnique.push(data.buyNowPunks[key].owner);
      }
      //remove duplicates
      const removeDuplicatedList = [...new Set(userListUnique)];
      const request = {
        PublicKeysBase58Check: removeDuplicatedList,
        SkipForLeaderboard: true,
      };
      const response2 = await deso.user.getUserStateless(request);
      const ownerList = response2.UserList;
      const ownerInfo = {};
      //loop through ownerList and make a dict with public key as key
      for (const key in ownerList) {
        const user = ownerList[key];
        const pk = user.PublicKeyBase58Check;
        ownerInfo[pk] = {
          username: user.ProfileEntryResponse
            ? user.ProfileEntryResponse.Username
            : "Anonymous",
          profilePic: user.ProfileEntryResponse
            ? user.ProfileEntryResponse.ExtraData
              ? user.ProfileEntryResponse.ExtraData.LargeProfilePicURL !== "" &&
                typeof user.ProfileEntryResponse.ExtraData
                  .LargeProfilePicURL !== "undefined"
                ? user.ProfileEntryResponse.ExtraData.LargeProfilePicURL
                : `https://diamondapp.com/api/v0/get-single-profile-picture/${pk}?fallback=https://diamondapp.com/assets/img/default-profile-pic.png`
              : `https://diamondapp.com/api/v0/get-single-profile-picture/${pk}?fallback=https://diamondapp.com/assets/img/default-profile-pic.png`
            : `https://diamondapp.com/api/v0/get-single-profile-picture/${pk}?fallback=https://diamondapp.com/assets/img/default-profile-pic.png`,
        };
      }
      setCheapApesOwnerInfo(ownerInfo);
   
      setLoadedInitialData(true);
    }
  }

  useEffect(() => {
    if (!loadedInitialData) {
      getCheapApes();
    }
  }, []);

  const buyNFT = async (postHashHex, serial, price) => {
    const loggedInPublicKey = localStorage.getItem("loggedInUser");

    if (!loggedInPublicKey) {
      toast.error("Please login to buy");
      return;
    }
    if (isBuying) {
      toast.error("Please wait for the previous transaction to complete");
      return;
    }
    const loadingToast = toast.loading("Please wait...");
    setIsBuying(true);
    const request = {
      UpdaterPublicKeyBase58Check: loggedInPublicKey,
      NFTPostHashHex: postHashHex,
      SerialNumber: parseInt(serial),
      BidAmountNanos: parseInt(price),
      MinFeeRateNanosPerKB: 1000,
    };
    try {
      const response = await deso.nft.createNftBid(request);

      setIsBuying(false);
      toast.dismiss(loadingToast);
      if (response) {
        const error = response.error;

        if (error) {
          toast.error(error);
          return;
        }
        toast.success("Transaction successful!");
        //remove that nft from cheap list
        //remove key postHashHex+ -serial from cheapApeInfo
        // const newCheapApeInfo = { ...cheapApeInfo };
        // delete newCheapApeInfo[postHashHex + "-" + serial];
        // setCheapApeInfo(newCheapApeInfo);

        let newList = [];
        for (const key in cheapApeInfo) {
          if (
            cheapApeInfo[key].postHashHex +
              "-" +
              cheapApeInfo[key].serialNumber !==
            postHashHex + "-" + serial
          ) {
            newList.push(cheapApeInfo[key]);
          }
        }
        setCheapApeInfo(newList);

        //make new post request and forget about response
        const res2 = await fetch("https://talkbuddy.in/update-nft-info", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            postHashHex: postHashHex,
          }),
        });
      } else {
        setIsBuying(false);
        toast.error("Something went wrong. This NFT might not be on sale");
        toast.dismiss(loadingToast);
      }
    } catch (e) {
      setIsBuying(false);
      toast.error("Something went wrong. This NFT might not be on sale");
      toast.dismiss(loadingToast);
    }
  };
  return (
    <div>
      <>
        <Navbar showLogin={true} showLogo={true} />
        <Toaster />
        <div className="pt-2  bg-[#150026]">
          <div className="  z-20">
            <h1 className="text-xl text-center text-white font-bold">
              Top Cheapest Gen1 CyberPunkApes
            </h1>

            {!loadedInitialData && (
              <div className="flex justify-center items-center h-screen mt-4 bg">
                <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-red-500"></div>
              </div>
            )}

            {cheapApeInfo && loadedInitialData && (
              <div className="mt-8">
                {cheapApeInfo.map((value, index) => (
                  <div
                    className="grid grid-cols-3 text-white my-4 text-sm sm:text-base "
                    key={index}
                  >
                    <a
                      className="flex space-x-1 items-center  justify-end"
                      href={`https://nftz.me/nft/${value.postHashHex}`}
                      target="_blank"
                    >
                      <img
                        src={`/CPA_ORIGINALS/${originalPunks[
                          value.postHashHex
                        ].name.slice(15, 18)}.jpg`}
                        className="w-9 h-9 sm:w-14 sm:h-14 object-contain rounded-md"
                      ></img>

                      <div className="">
                        #{" "}
                        <span>
                          {" "}
                          {originalPunks[value.postHashHex].name.slice(
                            15,
                            18
                          )}{" "}
                        </span>
                        <span>
                          <p>{`Serial: ${value.serialNumber}`}</p>
                        </span>
                      </div>
                    </a>

                    <div className="flex space-x-1 items-center underline justify-center">
                      <div
                        style={{
                          backgroundImage: `url(${
                            cheapApesOwnerInfo[value.owner].profilePic
                          })`,
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                        }}
                        className="w-10 h-10 object-contain rounded-full"
                      ></div>
                      <Link
                        className=" text-sm sm:text-base text-blue-300"
                        to={`/u/${cheapApesOwnerInfo[value.owner].username}`}
                      >
                        {cheapApesOwnerInfo[value.owner].username}
                      </Link>
                    </div>
                    <div>
                      <button
                        className="bg-green-500 hover:bg-green-700 text-white sm:font-bold py-2 px-6 rounded-md"
                        onClick={() => {
                          buyNFT(
                            value.postHashHex,
                            value.serialNumber,
                            value.buyNowPrice
                          );
                        }}
                      >
                        {Math.round(value.buyNowPrice / 1e6) / 1000} $DESO
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        <Footer />
      </>
    </div>
  );
};

export default Market;
