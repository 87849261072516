import React from "react";
import originstory from "../../assets/ORIGIN STORY.svg";
import triangle from "../../assets/Frame.svg";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
export default function OriginStory() {
  const navigate = useNavigate();

  return (
    <div className=" justify-center bg-[#350353] py-16">
      <img src={originstory} className="w-3/5 mx-auto" />
      <div className="w-full mb-4">
        <div className="h-1 mx-auto bg-white w-64 opacity-25 my-0 py-0 rounded-t"></div>
      </div>
      <div className="flex justify-center flex-col items-center py-12 px-2 sm:px-8 md:w-1/2 mx-auto">
        <p className="text-gray-200 text-xl text-center leading-normal z-20 font-semibold">
          The CyberPunkApes started as a fun DeSo Project to make crypto and
          NFTs fun again during Bear Market of 2022.
          <br></br>
          Everyday for 100 days we minted an NFT with 1000 copies which were
          given to community for FREE.
          <br></br>
          Now CyberPunkApes have started generation 2 and are on the way to
          become the most loved NFTs in crypto space. You can take part in the
          generation 2 journey by buying 25 CyberPunkApes NFT on secondary
          market to further safe stake and bid on Gen2 in order to earn
          Generation 2 NFT royalties. We also have a buy back of 80% of initial
          floor price of the NFT for one Year!
        </p>


        <Link
          to="/gen2"

          className="bg-green-500 hover:bg-green-600 text-white font-bold py-3 px-16 rounded-md mt-4 z-20"
        >
          View Gen2{" "}
        </Link>
        <a
          href="https://diamondapp.com/u/CyberPunkApes/blog/cyberpunkapes-desos-most-popular-nft-series?tab=posts"
          target={"_blank"}
          className="bg-[#150026] hover:bg-[#210c33] text-white font-bold py-3 px-8 rounded-md mt-8 z-20"
        >
          Learn More about G2
        </a>

       
      </div>

      <img
        src={triangle}
        className="w-80 -translate-y-80 right-0  absolute z-10"
      />
    </div>
  );
}
