import React, { useState, useEffect } from "react";
import NFTCard from "../../Components/NFTCard";
import { values, originals } from "../../tools/constants";
import Deso from "deso-protocol";
import InfiniteScroll from "react-infinite-scroll-component";
import toast, { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
export default function Showcase() {
  const deso = new Deso();
  const [tab, setTab] = useState("specials");
  const [specials, setSpecials] = useState(values);
  const [apeInfo, setApeInfo] = useState(null);
  const [holderInfo, setHolderInfo] = useState(null);

  const [originalPunks, setOriginalPunks] = useState(originals);
  const [originalPunksInfo, setOriginalPunksInfo] = useState(null);
  const [loadedOriginalPunksInfo, setLoadedOriginalPunksInfo] = useState(false);

  const [originalsOwnerList, setOriginalsOwnerList] = useState(null);

  //store all top punk owner info here from API
  const [topPunkOwners, setTopPunkOwners] = useState(null);

  // thsi will store get-userstateless for first 100 users
  const [topPunkOwnersInfo, setTopPunkOwnersInfo] = useState(null);
  const [loadedTopPunkOwners, setLoadedTopPunkOwners] = useState(false);
  const [displayTopPunkOwners, setDisplayTopPunkOwners] = useState(null);
  const [hasMoreTopPunkOwners, setHasMoreTopPunkOwners] = useState(true);
  const [offset, setOffset] = useState(100);
  const [totalOwners, setTotalOwners] = useState(null);

  const [originalPunkOffset, setOriginalPunkOffset] = useState(20);
  const [originalsHashMore, setOriginalsHashMore] = useState(true);

  const [displayOriginalPunks, setDisplayOriginalPunks] = useState(null);

  //this is for market tab
  const [cheapApeInfo, setCheapApeInfo] = useState(null);
  const [cheapApesOwnerInfo, setCheapApesOwnerInfo] = useState(null);
  const [loadingCheapApes, setLoadingCheapApes] = useState(true);

  const [isBuying, setIsBuying] = useState(false);
  useEffect(() => {
    async function getSpecialInfo() {
      // make POST REQUEST TO https://talkbuddy.in/get-ape-info

      //make list of all keys in values
      const nftHashHex = Object.keys(values);
      // add -1 to the end of each key
      const nftHashHexSerial = nftHashHex.map((key) => key + "-1");
      const response = await fetch("https://talkbuddy.in/get-ape-info", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          postHashHexList: nftHashHexSerial,
        }),
      });
      const data = await response.json();
      if (data.status === 200) {
        const apeInfoVal = data.apeInfo;
        setApeInfo(apeInfoVal);
        //loop thourhg apeInfoVal and make a list of owners
        const userList = [];
        for (const key in apeInfoVal) {
          const user = apeInfoVal[key].owner;
          userList.push(user);
        }
        //remove multiples from userList
        const userListUnique = [...new Set(userList)];
        const request = {
          PublicKeysBase58Check: userListUnique,
          SkipForLeaderboard: true,
        };
        const response2 = await deso.user.getUserStateless(request);
        const ownerList = response2.UserList;
        const ownerInfo = {};
        //loop through ownerList and make a dict with public key as key
        for (const key in ownerList) {
          const user = ownerList[key];
          const pk = user.PublicKeyBase58Check;
          ownerInfo[pk] = {
            username: user.ProfileEntryResponse
              ? user.ProfileEntryResponse.Username
              : "Anonymous",
            profilePic: user.ProfileEntryResponse
              ? user.ProfileEntryResponse.ExtraData
                ? user.ProfileEntryResponse.ExtraData.LargeProfilePicURL !== ""
                  ? user.ProfileEntryResponse.ExtraData.LargeProfilePicURL
                  : `https://diamondapp.com/api/v0/get-single-profile-picture/${pk}?fallback=https://diamondapp.com/assets/img/default-profile-pic.png`
                : `https://diamondapp.com/api/v0/get-single-profile-picture/${pk}?fallback=https://diamondapp.com/assets/img/default-profile-pic.png`
              : `https://diamondapp.com/api/v0/get-single-profile-picture/${pk}?fallback=https://diamondapp.com/assets/img/default-profile-pic.png`,
          };
        }

        setHolderInfo(ownerInfo);
      }
    }

    getSpecialInfo();
  }, []);

  useEffect(() => {
    async function getOriginalPunksInfo() {
      let starterPunkList = [];
      let completeArrayOfOriginals = Object.keys(originalPunks);
      let displayOriginalVal = {};
      for (let i = 0; i < 20; i++) {
        starterPunkList.push(completeArrayOfOriginals[i]);
        displayOriginalVal[completeArrayOfOriginals[i]] =
          originalPunks[completeArrayOfOriginals[i]];
      }
      setDisplayOriginalPunks(displayOriginalVal);
      const response = await fetch(
        "https://talkbuddy.in/get-ape-info-by-postHashHex",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            postHashHexList: starterPunkList,
            checkForSale: false,
          }),
        }
      );
      const data = await response.json();
      if (data.status === 200) {
        const apeInfoVal = data.apeInfo;
        const userListUnique = [];
        for (const key in apeInfoVal) {
          const pk1 = apeInfoVal[key].cheapestBuyNowOwner;
          const pk2 = apeInfoVal[key].serialOneInfo.owner;
          const mostlyOwnedBy = apeInfoVal[key].mostlyOwnedBy;
          if (pk1 && pk2) {
            userListUnique.push(pk1);

            userListUnique.push(pk2);
            userListUnique.push(mostlyOwnedBy);
          }
        }

        const removeDuplicatedList = [...new Set(userListUnique)];

        const request = {
          PublicKeysBase58Check: removeDuplicatedList,
          SkipForLeaderboard: true,
        };
        const response2 = await deso.user.getUserStateless(request);
        const ownerList = response2.UserList;
        const ownerInfo = {};
        //loop through ownerList and make a dict with public key as key
        for (const key in ownerList) {
          const user = ownerList[key];
          const pk = user.PublicKeyBase58Check;
          ownerInfo[pk] = {
            username: user.ProfileEntryResponse
              ? user.ProfileEntryResponse.Username
              : "Anonymous",
            profilePic: user.ProfileEntryResponse
              ? user.ProfileEntryResponse.ExtraData
                ? user.ProfileEntryResponse.ExtraData.LargeProfilePicURL !==
                    "" &&
                  typeof user.ProfileEntryResponse.ExtraData
                    .LargeProfilePicURL !== "undefined"
                  ? user.ProfileEntryResponse.ExtraData.LargeProfilePicURL
                  : `https://diamondapp.com/api/v0/get-single-profile-picture/${pk}?fallback=https://diamondapp.com/assets/img/default-profile-pic.png`
                : `https://diamondapp.com/api/v0/get-single-profile-picture/${pk}?fallback=https://diamondapp.com/assets/img/default-profile-pic.png`
              : `https://diamondapp.com/api/v0/get-single-profile-picture/${pk}?fallback=https://diamondapp.com/assets/img/default-profile-pic.png`,
          };
        }

        setOriginalsOwnerList(ownerInfo);

        setOriginalPunksInfo(apeInfoVal);
        setLoadedOriginalPunksInfo(true);
      }
    }

    async function getTopOwners() {
      const response = await fetch("https://talkbuddy.in/top-punk-owners", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          limit: 10000,
        }),
      });
      const data = await response.json();
      if (data.status === 200) {
        const topPunkOwnerVal = data.topPunkOwners;
        let userListUnique = [];
        let displayPunkVal = {};
        for (const key in topPunkOwnerVal) {
          displayPunkVal[key] = [
            topPunkOwnerVal[key][0],
            topPunkOwnerVal[key][1],
          ];

          userListUnique.push(topPunkOwnerVal[key][0]);
          if (key === "100") {
            break;
          }
        }

        const request = {
          PublicKeysBase58Check: userListUnique,
          SkipForLeaderboard: true,
        };
        const response2 = await deso.user.getUserStateless(request);
        const ownerList = response2.UserList;
        const ownerInfo = {};
        //loop through ownerList and make a dict with public key as key
        for (const key in ownerList) {
          const user = ownerList[key];
          const pk = user.PublicKeyBase58Check;
          ownerInfo[pk] = {
            username: user.ProfileEntryResponse
              ? user.ProfileEntryResponse.Username
              : "Anonymous",
            profilePic: user.ProfileEntryResponse
              ? user.ProfileEntryResponse.ExtraData
                ? user.ProfileEntryResponse.ExtraData.LargeProfilePicURL !==
                    "" &&
                  typeof user.ProfileEntryResponse.ExtraData
                    .LargeProfilePicURL !== "undefined"
                  ? user.ProfileEntryResponse.ExtraData.LargeProfilePicURL
                  : `https://diamondapp.com/api/v0/get-single-profile-picture/${pk}?fallback=https://diamondapp.com/assets/img/default-profile-pic.png`
                : `https://diamondapp.com/api/v0/get-single-profile-picture/${pk}?fallback=https://diamondapp.com/assets/img/default-profile-pic.png`
              : `https://diamondapp.com/api/v0/get-single-profile-picture/${pk}?fallback=https://diamondapp.com/assets/img/default-profile-pic.png`,
          };
        }

        setTotalOwners(Object.keys(topPunkOwnerVal).length);
        setTopPunkOwners(topPunkOwnerVal);

        setTopPunkOwnersInfo(ownerInfo);
        setDisplayTopPunkOwners(displayPunkVal);
        setLoadedTopPunkOwners(true);
      }
    }

    async function getCheapApes() {
      const response = await fetch("https://talkbuddy.in/get-buyNow-punks", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          limit: 300,
          order: "ascending",
        }),
      });
      const data = await response.json();
      if (data.status === 200) {
        //create a list of cheapApes sorted buy buyNowPrice
        let cheapApeList = [];
        for (const key in data.buyNowPunks) {
          let newDict = {
            buyNowPrice: data.buyNowPunks[key].buyNowPrice,
            owner: data.buyNowPunks[key].owner,
            minBidDeso: data.buyNowPunks[key].minBidDeso,
            buyNowPrice: data.buyNowPunks[key].buyNowPrice,
            postHashHex: key.split("-")[0],
            serialNumber: key.split("-")[1],
          };

          cheapApeList.push(newDict);
        }
        cheapApeList.sort((a, b) => a.buyNowPrice - b.buyNowPrice);

        setCheapApeInfo(cheapApeList);
        let userListUnique = [];
        for (const key in data.buyNowPunks) {
          userListUnique.push(data.buyNowPunks[key].owner);
        }
        //remove duplicates
        const removeDuplicatedList = [...new Set(userListUnique)];
        const request = {
          PublicKeysBase58Check: removeDuplicatedList,
          SkipForLeaderboard: true,
        };
        const response2 = await deso.user.getUserStateless(request);
        const ownerList = response2.UserList;
        const ownerInfo = {};
        //loop through ownerList and make a dict with public key as key
        for (const key in ownerList) {
          const user = ownerList[key];
          const pk = user.PublicKeyBase58Check;
          ownerInfo[pk] = {
            username: user.ProfileEntryResponse
              ? user.ProfileEntryResponse.Username
              : "Anonymous",
            profilePic: user.ProfileEntryResponse
              ? user.ProfileEntryResponse.ExtraData
                ? user.ProfileEntryResponse.ExtraData.LargeProfilePicURL !==
                    "" &&
                  typeof user.ProfileEntryResponse.ExtraData
                    .LargeProfilePicURL !== "undefined"
                  ? user.ProfileEntryResponse.ExtraData.LargeProfilePicURL
                  : `https://diamondapp.com/api/v0/get-single-profile-picture/${pk}?fallback=https://diamondapp.com/assets/img/default-profile-pic.png`
                : `https://diamondapp.com/api/v0/get-single-profile-picture/${pk}?fallback=https://diamondapp.com/assets/img/default-profile-pic.png`
              : `https://diamondapp.com/api/v0/get-single-profile-picture/${pk}?fallback=https://diamondapp.com/assets/img/default-profile-pic.png`,
          };
        }
        setCheapApesOwnerInfo(ownerInfo);
        setLoadingCheapApes(false);
      }
    }
    if (tab === "originals" && !originalPunksInfo) {
      getOriginalPunksInfo();
    }
    if (tab === "top" && !topPunkOwners) {
      getTopOwners();
    }
    if (tab === "market" && !cheapApeInfo) {
      getCheapApes();
    }
  }, [tab]);

  const fetchMoreOriginals = async () => {
    let nextPunkList = [];
    let completeArrayOfOriginals = Object.keys(originalPunks);
    let count = originalPunkOffset;
    let displayOriginalVal = {};
    for (let i = count; i < count + 20; i++) {
      if (i < 90) {
        nextPunkList.push(completeArrayOfOriginals[i]);
        displayOriginalVal[completeArrayOfOriginals[i]] =
          originalPunks[completeArrayOfOriginals[i]];
      }
    }

    if (nextPunkList.length === 0) {
      return;
    }

    const response = await fetch(
      "https://talkbuddy.in/get-ape-info-by-postHashHex",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          postHashHexList: nextPunkList,
          checkForSale: false,
        }),
      }
    );
    const data = await response.json();
    if (data.status === 200) {
      const apeInfoVal = data.apeInfo;
      const userListUnique = [];
      for (const key in apeInfoVal) {
        const pk1 = apeInfoVal[key].cheapestBuyNowOwner;
        const pk2 = apeInfoVal[key].serialOneInfo.owner;
        const mostlyOwnedBy = apeInfoVal[key].mostlyOwnedBy;
        if (pk1 && pk2) {
          userListUnique.push(pk1);

          userListUnique.push(pk2);
          userListUnique.push(mostlyOwnedBy);
        }
      }

      const removeDuplicatedList = [...new Set(userListUnique)];
      //remove all values from removeDuplicatedList that are in originalsOwnerList
      for (const key in originalsOwnerList) {
        const index = removeDuplicatedList.indexOf(key);
        if (index > -1) {
          removeDuplicatedList.splice(index, 1);
        }
      }

      const request = {
        PublicKeysBase58Check: removeDuplicatedList,
        SkipForLeaderboard: true,
      };
      const response2 = await deso.user.getUserStateless(request);
      const ownerList = response2.UserList;
      const ownerInfo = {};
      //loop through ownerList and make a dict with public key as key
      for (const key in ownerList) {
        const user = ownerList[key];
        const pk = user.PublicKeyBase58Check;
        ownerInfo[pk] = {
          username: user.ProfileEntryResponse
            ? user.ProfileEntryResponse.Username
            : "Anonymous",
          profilePic: user.ProfileEntryResponse
            ? user.ProfileEntryResponse.ExtraData
              ? user.ProfileEntryResponse.ExtraData.LargeProfilePicURL !== "" &&
                typeof user.ProfileEntryResponse.ExtraData
                  .LargeProfilePicURL !== "undefined"
                ? user.ProfileEntryResponse.ExtraData.LargeProfilePicURL
                : `https://diamondapp.com/api/v0/get-single-profile-picture/${pk}?fallback=https://diamondapp.com/assets/img/default-profile-pic.png`
              : `https://diamondapp.com/api/v0/get-single-profile-picture/${pk}?fallback=https://diamondapp.com/assets/img/default-profile-pic.png`
            : `https://diamondapp.com/api/v0/get-single-profile-picture/${pk}?fallback=https://diamondapp.com/assets/img/default-profile-pic.png`,
        };
      }

      setOriginalsOwnerList({ ...originalsOwnerList, ...ownerInfo });

      setOriginalPunksInfo({ ...originalPunksInfo, ...apeInfoVal });
      setOriginalPunkOffset(originalPunkOffset + 20);
      setDisplayOriginalPunks({
        ...displayOriginalPunks,
        ...displayOriginalVal,
      });
      if (originalPunkOffset > 100) {
        setOriginalsHashMore(false);
      }
    }
  };

  const fetchMoreTopOwners = async () => {
    let count = 0;
    let listOfPublicKeys = [];
    let newObj = {};
    for (const key in topPunkOwners) {
      count++;
      if (count > offset && count < offset + 100) {
        listOfPublicKeys.push(topPunkOwners[key][0]);

        newObj[key] = [topPunkOwners[key][0], topPunkOwners[key][1]];
      }
    }
    const request = {
      PublicKeysBase58Check: listOfPublicKeys,
      SkipForLeaderboard: true,
    };

    const response2 = await deso.user.getUserStateless(request);
    const ownerList = response2.UserList;
    const ownerInfo = {};

    //loop through ownerList and make a dict with public key as key
    for (const key in ownerList) {
      const user = ownerList[key];
      const pk = user.PublicKeyBase58Check;
      ownerInfo[pk] = {
        username: user.ProfileEntryResponse
          ? user.ProfileEntryResponse.Username
          : "Anonymous",
        profilePic: user.ProfileEntryResponse
          ? user.ProfileEntryResponse.ExtraData
            ? user.ProfileEntryResponse.ExtraData.LargeProfilePicURL !== "" &&
              typeof user.ProfileEntryResponse.ExtraData.LargeProfilePicURL !==
                "undefined"
              ? user.ProfileEntryResponse.ExtraData.LargeProfilePicURL
              : `https://diamondapp.com/api/v0/get-single-profile-picture/${pk}?fallback=https://diamondapp.com/assets/img/default-profile-pic.png`
            : `https://diamondapp.com/api/v0/get-single-profile-picture/${pk}?fallback=https://diamondapp.com/assets/img/default-profile-pic.png`
          : `https://diamondapp.com/api/v0/get-single-profile-picture/${pk}?fallback=https://diamondapp.com/assets/img/default-profile-pic.png`,
      };
    }
    setTopPunkOwnersInfo({ ...topPunkOwnersInfo, ...ownerInfo });
    setOffset(offset + 100);
    setDisplayTopPunkOwners({ ...displayTopPunkOwners, ...newObj });
    setHasMoreTopPunkOwners(offset > totalOwners ? false : true);
  };

  const buyNFT = async (postHashHex, serial, price) => {
    const loggedInPublicKey = localStorage.getItem("loggedInUser");

    if (!loggedInPublicKey) {
      toast.error("Please login to buy");
      return;
    }
    if (isBuying) {
      toast.error("Please wait for the previous transaction to complete");
      return;
    }
    const loadingToast = toast.loading("Please wait...");
    setIsBuying(true);
    const request = {
      UpdaterPublicKeyBase58Check: loggedInPublicKey,
      NFTPostHashHex: postHashHex,
      SerialNumber: parseInt(serial),
      BidAmountNanos: parseInt(price),
      MinFeeRateNanosPerKB: 1000,
    };
    try {
      const response = await deso.nft.createNftBid(request);

      setIsBuying(false);
      toast.dismiss(loadingToast);
      if (response) {
        const error = response.error;

        if (error) {
          toast.error(error);
          return;
        }
        toast.success("Transaction successful!");
        //remove that nft from cheap list
        //remove key postHashHex+ -serial from cheapApeInfo
        // const newCheapApeInfo = { ...cheapApeInfo };
        // delete newCheapApeInfo[postHashHex + "-" + serial];
        // setCheapApeInfo(newCheapApeInfo);

        let newList = [];
        for (const key in cheapApeInfo) {
          if (
            cheapApeInfo[key].postHashHex +
              "-" +
              cheapApeInfo[key].serialNumber !==
            postHashHex + "-" + serial
          ) {
            newList.push(cheapApeInfo[key]);
          }
        }
        setCheapApeInfo(newList);

        //make new post request and forget about response
        const res2 = await fetch("https://talkbuddy.in/update-nft-info", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            postHashHex: postHashHex,
          }),
        });
      } else {
        setIsBuying(false);
        toast.error("Something went wrong. This NFT might not be on sale");
        toast.dismiss(loadingToast);
      }
    } catch (e) {
      setIsBuying(false);
      toast.error("Something went wrong. This NFT might not be on sale");
      toast.dismiss(loadingToast);
    }
  };
  return (
    <div className='bg-[#150026]  py-10  z-20'>
      <Toaster />

    
      <div className='w-full px-2 justify-center mx-auto flex space-x-4 text-white font-semibold text-lg'>
        <button
          className={`${
            tab === "specials"
              ? "border-b-4 border-b-[#4f237c]"
              : "text-gray-400"
          } hover:text-white pb-2`}
          onClick={() => setTab("specials")}>
          Specials
        </button>
        <button
          className={`${
            tab === "originals"
              ? "border-b-4 border-b-[#4f237c]"
              : "text-gray-400"
          } hover:text-white pb-2`}
          onClick={() => setTab("originals")}>
          Originals
        </button>
        <button
          className={`${
            tab === "market" ? "border-b-4 border-b-[#4f237c]" : "text-gray-400"
          } hover:text-white pb-2`}
          onClick={() => setTab("market")}>
          Market
        </button>
        <button
          className={`${
            tab === "top" ? "border-b-4 border-b-[#4f237c]" : "text-gray-400"
          } hover:text-white pb-2`}
          onClick={() => setTab("top")}>
          Top Owners
        </button>
      </div>
      {tab === "specials" && (
        <div className='w-full px-2 sm:w-3/5 mx-auto'>
          <p className='text-white text-center mt-12'>
            There are only 10 1/1 Special Edition CyberPunkApes namely #10, #20,
            #30...#100. <br></br>
            Each were given to CyberPunkApes holder through a random lucky mint
            based on how many originals they held
          </p>
          {specials && (
            <div className='grid grid-cols-1 sm:grid-cols-2  gap-3 mt-12 justify-center'>
              {Object.keys(specials).map((key, index) => (
                <NFTCard
                  key={index}
                  value={specials[key]}
                  nftHashHex={key}
                  apeInfo={apeInfo}
                  holderInfo={holderInfo}
                />
              ))}
            </div>
          )}
        </div>
      )}

      {tab === "originals" && (
        <div className='w-full px-2  mx-auto'>
          <p className='text-white text-center mt-12'>
            There are 90 CyberPunkApes Originals each had 1000 edition at the
            time of mint. <br></br> All were given for free to first 1000 people
            who claimed it everyday.
          </p>
          {!loadedOriginalPunksInfo && (
            <div className='flex justify-center'>
              <div className='animate-spin rounded-full h-32 w-32 border-b-2 border-red-500 mt-4'></div>
            </div>
          )}
          {displayOriginalPunks && loadedOriginalPunksInfo && (
            <InfiniteScroll
              className='container mx-auto flex justify-center flex-col pt-4 pb-12'
              dataLength={Object.keys(displayOriginalPunks).length}
              next={fetchMoreOriginals}
              hasMore={originalsHashMore}
              loader={
                <div className='my-3 flex justify-center mx-auto'>
                  <div className='animate-spin rounded-full h-32 w-32 border-b-2 border-red-500'></div>
                </div>
              }>
              <div className='flex flex-col mt-12 mx-auto justify-center'>
                {Object.keys(displayOriginalPunks).map((key, index) => (
                  <div className='flex flex-col  space-y-2 my-4 '>
                    <div className='flex space-x-2 items-center my-3'>
                      <img
                        src={`/CPA_ORIGINALS/${displayOriginalPunks[
                          key
                        ].name.slice(15, 18)}.jpg`}
                        className='w-24 h-24 object-contain rounded-md'
                      />

                      <a
                        className='text-white font-semibold text-lg underline'
                        href={`https://diamondapp.com/nft/${key}`}
                        target='_blank'>
                        {displayOriginalPunks[key].name.slice(0, 18)} ||{" "}
                        {originalPunksInfo[key].totalSerials} Serials
                      </a>
                    </div>
                    <div className='flex flex-col  space-y-2 '>
                      <p className='text-white text-sm sm:text-base'>
                        Unique Owners: {originalPunksInfo[key].uniqueOwners}
                      </p>
                      {originalPunksInfo[key].isAnyAtSale && (
                        <div className='text-white sm:flex items-center space-x-1 text-sm sm:text-base '>
                          <div className='flex'>
                            <p className='text-white text-sm sm:text-base'>
                              {" "}
                              Buy Now Starting at{" "}
                            </p>

                            <p className='ml-1'>
                              {Math.round(
                                originalPunksInfo[key].cheapestBuyNowAt / 1e6
                              ) / 1000}{" "}
                              $DESO for serial-{" "}
                            </p>
                            <p>
                              {"  "}{" "}
                              {originalPunksInfo[key].cheapestBuyNowSerial}{" "}
                            </p>
                          </div>
                        </div>
                      )}
                      {!originalPunksInfo[key].isAnyAtSale && (
                        <div className='text-red-500  py-1 rounded-md my-2 '>
                          Not For Sale
                        </div>
                      )}

                      <div className='text-white  flex space-x-2 items-center'>
                        <p className='text-white text-sm sm:text-base'>
                          {" "}
                          #1 Serial Owned by:{" "}
                        </p>
                        <div className='flex space-x-1 items-center underline'>
                          <div
                            style={{
                              backgroundImage: `url(${
                                originalsOwnerList[
                                  originalPunksInfo[key].serialOneInfo.owner
                                ].profilePic
                              })`,
                              backgroundPosition: "center",
                              backgroundSize: "cover",
                            }}
                            className='w-10 h-10 object-contain rounded-full'></div>
                          <Link
                            to={`/u/${
                              originalsOwnerList[
                                originalPunksInfo[key].serialOneInfo.owner
                              ].username
                            }`}
                            className=' text-sm sm:text-base text-blue-300'>
                            {
                              originalsOwnerList[
                                originalPunksInfo[key].serialOneInfo.owner
                              ].username
                            }
                          </Link>
                        </div>
                      </div>

                      <div className='text-white flex items-center space-x-1'>
                        <p className='text-white text-sm sm:text-base'>
                          Most Serials ({originalPunksInfo[key].amountOwned}{" "}
                          serials) Owned by:{" "}
                        </p>
                        <div className='flex space-x-1 items-center underline'>
                          <div
                            style={{
                              backgroundImage: `url(${
                                originalsOwnerList[
                                  originalPunksInfo[key].mostlyOwnedBy
                                ].profilePic
                              })`,
                              backgroundPosition: "center",
                              backgroundSize: "cover",
                            }}
                            className='w-10 h-10 object-contain rounded-full'></div>
                          <Link
                            className=' text-sm sm:text-base text-blue-300'
                            to={`/u/${
                              originalsOwnerList[
                                originalPunksInfo[key].mostlyOwnedBy
                              ].username
                            }`}>
                            {
                              originalsOwnerList[
                                originalPunksInfo[key].mostlyOwnedBy
                              ].username
                            }
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </InfiniteScroll>
          )}
        </div>
      )}

      {tab === "top" && (
        <div className='w-full px-2 mx-auto'>
          <p className='text-white text-center mt-12'>
            Here are the top Owners of CyberPunkApes.
          </p>

          {!loadedTopPunkOwners && (
            <div className='flex justify-center'>
              <div className='animate-spin rounded-full h-32 w-32 border-b-2 border-red-500 mt-4'></div>
            </div>
          )}
          {displayTopPunkOwners && loadedTopPunkOwners && (
            <InfiniteScroll
              className='container mx-auto  pt-4 pb-12'
              dataLength={Object.keys(displayTopPunkOwners).length}
              next={fetchMoreTopOwners}
              hasMore={hasMoreTopPunkOwners}
              loader={
                <div className='my-3 flex justify-center mx-auto'>
                  <div className='animate-spin rounded-full h-32 w-32 border-b-2 border-red-500'></div>
                </div>
              }>
              <div className=' mt-12 mx-auto '>
                {Object.keys(displayTopPunkOwners).map((key, index) => (
                  <div className='grid  mx-auto grid-cols-2 space-y-2 my-4 text-white items-center'>
                    <div className='flex space-x-1 items-center underline justify-end'>
                      <div
                        style={{
                          backgroundImage: `url(${
                            topPunkOwnersInfo[displayTopPunkOwners[key][0]]
                              .profilePic
                          })`,
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                        }}
                        className='w-10 h-10 object-contain rounded-full'></div>
                      <a
                        className=' text-sm sm:text-base text-blue-300'
                        href={`https://diamondapp.com/u/${
                          topPunkOwnersInfo[displayTopPunkOwners[key][0]]
                            .username
                        }`}
                        target='_blank'>
                        {
                          topPunkOwnersInfo[displayTopPunkOwners[key][0]]
                            .username
                        }
                      </a>
                    </div>
                    <div className='px-8'>
                      <p> {displayTopPunkOwners[key][1]}</p>
                    </div>
                  </div>
                ))}
              </div>
            </InfiniteScroll>
          )}
        </div>
      )}

      {tab === "market" && (
        <div className='w-full px-2 mx-auto'>
          <p className='text-white text-center mt-6'>
            Top Cheapest CyberPunkApes
          </p>
          <div className='grid grid-cols-3 text-white my-4 text-sm sm:text-base mt-5'>
            <p className='justify-end flex'>CyberPunkApes</p>
            <p className='justify-center flex'>Owner</p>
            <p className='justify-center'>Buy Now</p>
          </div>
          {loadingCheapApes && (
            <div className='flex justify-center'>
              <div className='animate-spin rounded-full h-32 w-32 border-b-2 border-red-500 mt-4'></div>
            </div>
          )}
          {cheapApeInfo && !loadingCheapApes && (
            <div className='mt-8'>
              {cheapApeInfo.map((value, index) => (
                <div
                  className='grid grid-cols-3 text-white my-4 text-sm sm:text-base '
                  key={index}>
                  <a
                    className='flex space-x-1 items-center  justify-end'
                    href={`https://diamondapp.com/nft/${value.postHashHex}`}
                    target='_blank'>
                    <img
                      src={`/CPA_ORIGINALS/${originalPunks[
                        value.postHashHex
                      ].name.slice(15, 18)}.jpg`}
                      className='w-9 h-9 sm:w-14 sm:h-14 object-contain rounded-md'></img>

                    <div className=''>
                      #{" "}
                      <span>
                        {" "}
                        {originalPunks[value.postHashHex].name.slice(
                          15,
                          18
                        )}{" "}
                      </span>
                      <span>
                        <p>{`Serial: ${value.serialNumber}`}</p>
                      </span>
                    </div>
                  </a>

                  <div className='flex space-x-1 items-center underline justify-center'>
                    <div
                      style={{
                        backgroundImage: `url(${
                          cheapApesOwnerInfo[value.owner].profilePic
                        })`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                      }}
                      className='w-10 h-10 object-contain rounded-full'></div>
                    <Link
                      className=' text-sm sm:text-base text-blue-300'
                      to={`u/${cheapApesOwnerInfo[value.owner].username}`}>
                      {cheapApesOwnerInfo[value.owner].username}
                    </Link>
                  </div>
                  <div>
                    <button
                      className='bg-green-500 hover:bg-green-700 text-white sm:font-bold py-2 px-6 rounded-md'
                      onClick={() => {
                        buyNFT(
                          value.postHashHex,
                          value.serialNumber,
                          value.buyNowPrice
                        );
                      }}>
                      {Math.round(value.buyNowPrice / 1e6) / 1000} $DESO
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}

    </div>
  );
}
