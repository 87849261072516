import React, { useState, useEffect } from "react";
import Navbar from "../../Components/Navbar";
import Footer from "../Landing/Footer";
import Deso from "deso-protocol";
import Gen2Card from "./Gen2Card";
import { Link } from "react-router-dom";
const Gen2Page = () => {
  const deso = new Deso();
  const INFO_POST_HASH_HEX =
    "0f708032979dc801219af6390dc00310913a877c6c394420aa96f7e7a22c9961";
  
    const INFO_POST_HASH_HEX2 = "6175c513005894d871eca62a47d2203efc68c7744a6e09a4a0e0eb5444a29514"

    const INFO__POST_HASH_HEX3 = "7120ca8a382365b04275293c204323276879150fd45fe6487a6cd537e646dd4d"
  const [loadedInitialData, setLoadedInitialData] = useState(false);
  const [allNftInfo, setAllNftInfo] = useState([]);
  const [allNftEntries, setAllNftEntries] = useState([]);
  const [totalGen1sBurned, setTotalGen1sBurned] = useState(0);
  const [totalMarketDeso, setTotalMarketDeso] = useState(0);
  async function getGen2Info() {
    const request = {
      PostHashHex: INFO_POST_HASH_HEX,
    };
    const request2 = {
      PostHashHex: INFO_POST_HASH_HEX2,
    }

    const request3 = {
      PostHashHex: INFO__POST_HASH_HEX3,
    }
    const singlePostInfo = await deso.posts.getSinglePost(request);
    const singlePostInfo2 = await deso.posts.getSinglePost(request2);
    const singlePostInfo3 = await deso.posts.getSinglePost(request3);
    const bodyString = singlePostInfo.PostFound.Body;
    const bodyString2 = singlePostInfo2.PostFound.Body;
    const bodyString3 = singlePostInfo3.PostFound.Body;
    //bodyString is a string of json data. convert to object
    const bodyObject = JSON.parse(bodyString);
    const bodyObject2 = JSON.parse(bodyString2);
    const bodyObject3 = JSON.parse(bodyString3);
    //reverse the keys in bodyObject
    // add the dictionary bodyObject2 to bodyObject
    const finalBodyObject = {...bodyObject, ...bodyObject2, ...bodyObject3}
    const reversedKeys = Object.keys(finalBodyObject).reverse();
    //loop through reversedKeys
    const tempNftInfo = [];
    let tempTotalGen1sBurned = 0;
    let tempTotalMarketDeso = 0;
    for (let i = 0; i < reversedKeys.length; i++) {
      const key = reversedKeys[i];
      const value = finalBodyObject[key];
      tempTotalGen1sBurned += value.tb;
      tempTotalMarketDeso += value.ip? value.ip : 0;

      //push value to allNftInfo
      tempNftInfo.push(value);
    }
    setTotalMarketDeso(tempTotalMarketDeso);
    setTotalGen1sBurned(tempTotalGen1sBurned);
    setAllNftInfo(tempNftInfo);

    setLoadedInitialData(true);
  }

  useEffect(() => {
    if (!loadedInitialData) {
      getGen2Info();
    }
  }, []);
  return (
    <div>
      <>
        <Navbar showLogin={true} showLogo={true} />
        <div className="pt-20  bg-[#150026]">
          <div className="  z-20">
            <h1 className="text-4xl text-center text-white font-bold">
              Generation 2 of CyberPunkApes
            </h1>
            <p className="text-white text-center mt-12 text-lg sm:px-8">
              Generation 2 are evolved CyberPunkApes on DeSo Blockchain.{" "}
              <br></br> Each Gen2 is unique and has a unique DNA traits which
              are minted every 24 hours. <br></br> To get a Gen2 CyberPunkApe,
              you need to bid on Gen2s by staking 25 Gen1s. Minimum bid starts
              with 1 $DESO. <br></br> <br></br>If you win the bid, all your
             25 staked Gen1s are burned. If you loose the bid, only 3 of your staked
              Gen1s are burned and you get a shared royalty of 4% on the NFT.
              <br></br>
              All Gen2s have a buy back of 80% of the initial price for one
              year. 
            </p>
            <div className="flex justify-center">
              <a
                href="https://diamondapp.com/u/CyberPunkApes/blog/cyberpunkapes-desos-most-popular-nft-series?tab=posts"
                target={"_blank"}
                className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-3 px-8 rounded-md mt-8 z-20"
              >
                Learn More about Gen-2
              </a>

              
            </div>
            <div className="flex justify-center">
              <Link
                to="/market"
              
                className="bg-green-600 hover:bg-green-700 text-white font-bold py-3 px-20 rounded-md mt-8 z-20"
              >
                Buy Gen1s
              </Link>

              
            </div>
            {!loadedInitialData && (
              <div className="flex justify-center items-center h-screen mt-4 bg">
                <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-red-500"></div>
              </div>
            )}


{loadedInitialData && (
  <div>
    <p className="text-white text-center mt-10 text-lg sm:px-8 font-semibold">
                Total Gen1s burned for Gen2s so far: {totalGen1sBurned}
            </p>

<p className="text-white text-center mt-2 text-lg sm:px-8 font-semibold">
Initial Market of Gen2s: {totalMarketDeso} $DESO
</p>
</div>
)}


            {loadedInitialData && (
              <div className="w-full px-2 sm:w-3/4 mx-auto">
                <div className="grid grid-cols-1 sm:grid-cols-2  gap-3 mt-12 justify-center">
                  {Object.keys(allNftInfo).map((key, index) => (
                    <Gen2Card key={index} value={allNftInfo[key]} />
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>

        <Footer />
      </>
    </div>
  );
};

export default Gen2Page;
