import React from "react";

export default function Footer() {
  return (
    <footer
      className={`text-center lg:text-left  bg-[#150026] text-white py-10 h-80 relative`}>
      <div className='w-full px-2 sm:w-3/5 mx-auto'>
        <div>
          <p className='text-green-400 text-xl ml-2 font-bold'> Created By</p>
          <div className='flex flex-col mt-4 '>
            <a
              className='flex space-x-3 items-center'
              href='https://diamondapp.com/u/ItsAditya'
              target='_blank'>
              <img
                src='https://images.bitclout.com/7561dce8a78e266e33ce4b3340f8b60ce57e2a2dcb17aa22fe2fc0eac5c14432.webp'
                className='h-24 w-24 rounded-full'
              />

              <div>
                <p className='text-[#BB0168] text-xl font-semibold'>
                  {" "}
                  ItsAditya
                </p>
                <p className='text-gray-600 text-xl italic font-semibold'>
                  {" "}
                  Aditya Co-founder, developer extraordinaire
                </p>
              </div>
            </a>
          </div>
          <div className='flex flex-col mt-3'>
            <a
              className='flex space-x-3 items-center'
              href='https://diamondapp.com/u/Nathanwells'
              target='_blank'>
              <img
                src='https://vj7gavfqcyo2ii6xykm6no3csihnja7fpegcfcwafcl5gzmthaoa.arweave.net/qn5gVLAWHaQj18KZ5rtikg7Ug-V5DCKKwCiX02WTOBw'
                className='h-24 w-24 rounded-full'
              />

              <div>
                <p className='text-[#BB0168] text-xl font-semibold'>
                  {" "}
                  Nathanwells
                </p>
                <p className='text-gray-600 text-xl italic font-semibold'>
                  {" "}
                  Co-founder, LIX & NFT artist specializing in
                  AI-generated artwork
                </p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
