import React from "react";
import { Link } from "react-router-dom";
export default function Gen2Card({ value }) {
  return (
    <div>
      <div className="flex flex-col justify-center items-center relative px-2 sm:px-4 my-1 sm:my-2 lg:my-3 ">
        <a
          href={
            value.isNFT ? `https://nftz.me/nft/${value.p}` : `/gen2/${value.p}`
          }
          target={value.isNFT && "_blank"}
        >
          <img
            className="w-full rounded-t-md hover:shadow-purple-800 hover:shadow-lg"
            src={value.img}
          />
        </a>
        <div className="bg-[#350353] w-full rounded-b-md px-2 py-3">
          <h1 className="text-white font-semibold">{value.b}</h1>
          {value.isNFT && (
            <p className="text-white font-semibold my-2">
              Initial Floor: {value.ip ? value.ip : 0} $DESO{" "}
            </p>
          )}
          {!value.isNFT && (
            <div className="my-2">
              <Link
                to={`/gen2/${value.p}`}
                className="text-white font-semibold px-4 py-1 bg-green-500 hover:bg-green-600 rounded-md"
              >
                Bid starts at 1 $DESO
              </Link>
            </div>
          )}
          {value.isNFT && (
            <p className="text-white font-semibold my-2">
              No. of Gen1s Burned: {value.tb}
            </p>
          )}

          {value.isNFT && (
            <div className="text-white font-semibold my-2 flex items-center space-x-2">
              Owned by
              <div className="flex items-center text-white text-lg ml-2">
                <img
                  src={`https://diamondapp.com/api/v0/get-single-profile-picture/${value.ok}?fallback=https://diamondapp.com/assets/img/default-profile-pic.png`}
                  className="w-10 h-10 rounded-full"
                />
                <p className="font-semibold">{value.on}</p>
              </div>
            </div>
          )}
          {
            <div className="flex text-white items-center space-x-2 my-2 font-semibold justify-between flex-col xl:flex-row">
              <div className="flex space-x-2 items-center">
                {/* <Link
                  to={`/u/${
                    holderInfo[apeInfo[`${nftHashHex}-1`].owner].username
                  }`}
                  className='text-[#BB0163] underline font-semibold'>
                  {holderInfo[apeInfo[`${nftHashHex}-1`].owner].username}
                </Link> */}

                {/* <div
                  style={{
                    backgroundImage: `url(${
                      holderInfo[apeInfo[`${nftHashHex}-1`].owner].profilePic
                    })`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                  className='w-10 h-10 rounded-full'></div> */}
              </div>

              {/* {apeInfo[`${nftHashHex}-1`].isForSale === "0" ? (
                <div className='bg-gray-900 text-gray-200 px-4 py-1 rounded-md my-2'>
                  Not For Sale
                </div>
              ) : (
                <button className='bg-green-600 text-gray-100 px-4 py-1 rounded-md hover:bg-green-700 text-sm lg:text-base my-2'>
                  Bid {Math.round(apeInfo[`${nftHashHex}-1`].minBidDeso / 1e9)}{" "}
                  $DESO
                </button>
              )} */}
            </div>
          }
        </div>
      </div>
    </div>
  );
}
